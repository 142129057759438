
const TimestampTo_YYYYMMDD_HHMMSS = (timestamp: number) : string => {
    if (timestamp > 0 && timestamp < 1000000000000) {
        timestamp *= 1000
    }
    let date = new Date(timestamp)
    let yearStr = date.getFullYear().toString()
    let monthStr = (date.getMonth() + 1).toString()
    let dayStr = date.getDate().toString()
    let hourStr = date.getHours().toString()
    let minuteStr = date.getMinutes().toString()
    let secondStr = date.getSeconds().toString()
    if (hourStr.length < 2) {
        hourStr = '0' + hourStr
    }
    if (minuteStr.length < 2) {
        minuteStr = '0' + minuteStr
    }
    if (secondStr.length < 2) {
        secondStr = '0' + secondStr
    }
    return yearStr + '年' + monthStr + '月' + dayStr + '日 ' + hourStr + ':' + minuteStr + ':' + secondStr
}

const TimestampTo_YYYYMMDD = (timestamp: number) : string => {
    if (timestamp > 0 && timestamp < 1000000000000) {
        timestamp *= 1000
    }
    let date = new Date(timestamp)
    let yearStr = date.getFullYear().toString()
    let monthStr = (date.getMonth() + 1).toString()
    let dayStr = date.getDate().toString()
    return yearStr + '年' + monthStr + '月' + dayStr + '日'
}

const TimestampTo_HHMMSS = (timestamp: number) : string => {
    if (timestamp > 0 && timestamp < 1000000000000) {
        timestamp *= 1000
    }
    let date = new Date(timestamp)
    let hourStr = date.getHours().toString()
    let minuteStr = date.getMinutes().toString()
    let secondStr = date.getSeconds().toString()
    if (hourStr.length < 2) {
        hourStr = '0' + hourStr
    }
    if (minuteStr.length < 2) {
        minuteStr = '0' + minuteStr
    }
    if (secondStr.length < 2) {
        secondStr = '0' + secondStr
    }
    return hourStr + ':' + minuteStr + ':' + secondStr
}


export {
    TimestampTo_YYYYMMDD_HHMMSS,
    TimestampTo_YYYYMMDD,
    TimestampTo_HHMMSS,
}
