import React, {useEffect, useRef, useState} from "react";

import styles from "./index.module.css"
import AppBar from "../../compoment/AppBar";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Button, DatePicker,
    Dialog,
    Form,
    Input,
    NumberKeyboard, Radio,
    Result,
    Space,
    TextArea,
    Toast,
    VirtualInput
} from "antd-mobile";
import LoadState from "../../definition/load_state";
import {TimestampTo_HHMMSS, TimestampTo_YYYYMMDD} from "../../utils/format_utils";
import {
    NetworkBillRecordCreate, NetworkBillRecordDelete,
    NetworkBillRecordInfo, NetworkBillRecordType,
    NetworkBillRecordUpdate
} from "../../module/network/network_billrecord";
import TimePicker from "../../compoment/TimePicker";
import BillBookPicker from "../../compoment/BillBookPicker";
import BillBookCache from "../../module/cache/billbook_cache";
import Router from "../../route/router";
import URLSearchParamsExtensions from "../../utils/url_search_params_extensions";
import EventCenter from "../../module/event/event_center";
import {EventBillRecordAdd, EventBillRecordEdit} from "../../module/event/events_bill_record";
import BillTagPicker from "../../compoment/BillTagPicker";
import BillTagCache, {BillTag} from "../../module/cache/billtag_cache";
import Tag from "../../compoment/Tag";

const BillBookAddEdit = (): React.JSX.Element => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const [pageState, setPageState] = useState(LoadState.Loading)
    const [isAddBillRecord] = useState(id === undefined)
    const [isRequesting, setIsRequesting] = useState(false)
    const [isShowDatePicker, setIsShowDatePicker] = useState(false)
    const [isShowTimePicker, setIsShowTimePicker] = useState(false)
    const [isShowBillBookPicker, setIsShowBillBookPicker] = useState(false)
    const [isShowBillTagPicker, setIsShowBillTagPicker] = useState(false)

    const [billRecordID, setBillRecordID] = useState(0)
    const [billRecordName, setBillRecordName] = useState('')
    const [billRecordMoney, setBillRecordMoney] = useState('0')
    const [billRecordRemark, setBillRecordRemark] = useState('')
    const [billRecordTime, setBillRecordTime] = useState(Date.now())
    const [billRecordBillBookID, setBillRecordBillBookID] = useState(0)
    const [billRecordBillBookName, setBillRecordBillBookName] = useState('请选择账本')
    const [billRecordType, setBillRecordType] = useState(NetworkBillRecordType.OutCome)
    const [billRecordTag, setBillRecordTag] = useState(new Array<BillTag>())

    const actionOnAddClick = () => {
        if (isRequesting) {
            return
        }
        if (billRecordBillBookID <= 0) {
            Toast.show('请选择账本')
            return
        }
        let requestMoney = 0
        try {
            requestMoney = parseFloat(billRecordMoney)
        } catch (e) {
            console.log(e)
            Toast.show('金额格式错误')
            return
        }
        // 状态修改
        setIsRequesting(true)
        // 进行请求
        NetworkBillRecordCreate({
            billBookID: billRecordBillBookID,
            name: billRecordName,
            money: requestMoney,
            time: Math.floor(billRecordTime / 1000),
            remark: billRecordRemark,
            type: billRecordType,
            tags: billRecordTag.map((tag) => tag.id)
        }).then(() => {
            Toast.show({
                icon: "success",
                content: '账单记录好啦'
            })
            setIsRequesting(false)
            EventCenter.notify(new EventBillRecordAdd())
            Router.goBack(navigate)
        }).catch((e) => {
            Toast.show({
                icon: 'fail',
                content: e.toString(),
            })
            setIsRequesting(false)
        })
    }

    const actionOnEditClick = () => {
        if (isRequesting) {
            return
        }
        if (billRecordBillBookID <= 0) {
            Toast.show('无账本信息，请刷新')
            return
        }
        let requestMoney = 0
        try {
            requestMoney = parseFloat(billRecordMoney)
        } catch (e) {
            Toast.show('金额格式错误')
            return
        }

        // 状态修改
        setIsRequesting(true)
        // 进行请求
        NetworkBillRecordUpdate({
            id: billRecordID,
            name: billRecordName,
            money: requestMoney,
            remark: billRecordRemark,
            type: billRecordType,
            time: Math.floor(billRecordTime / 1000),
            tags: billRecordTag.map((tag) => tag.id)
        }).then(result => {
            setIsRequesting(false)
            Toast.show({
                icon: "success",
                content: '账单更新成功'
            })
            // 发送事件
            EventCenter.notify(new EventBillRecordEdit())
            // 返回上一个界面
            Router.goBack(navigate)
        }).catch(error => {
            console.log(error)
            setIsRequesting(false)
            Toast.show({
                icon: 'fail',
                content: error.toString(),
            })
        })
    }

    const actionOnDeleteClick = () => {
        // 弹窗确定
        Dialog.confirm({
            title: '删除账单',
            content: '您确定要删除这个账单吗？这会被永久删除哦！',
            confirmText: (<span className={styles.textDanger}>删除</span>),
            onConfirm: () => {
                NetworkBillRecordDelete(billRecordID).then(() => {
                    Toast.show({
                        icon: 'success',
                        content: '账单删除成功'
                    })
                    Router.goBack(navigate)
                }).catch((error) => {
                    console.log(error)
                    Toast.show({
                        icon: 'fail',
                        content: error.toString(),
                    })
                })
            }
        })
    }

    const actionInit = () => {
        // 加载中
        setPageState(LoadState.Loading)
        // 读取参数
        const queryParams = new URLSearchParams(location.search)
        const defaultBillRecordID = URLSearchParamsExtensions.getInt(queryParams, 'billbook_id')
        console.log('预制账单ID', defaultBillRecordID)
        // 加载
        if (isAddBillRecord) {
            // 新增账单
            setPageState(LoadState.Success)
            // 设置页面跳转的传参
            if (defaultBillRecordID) {
                BillBookCache.getBillBook(defaultBillRecordID).then(cacheBillBook => {
                    if (cacheBillBook) {
                        setBillRecordBillBookID(cacheBillBook.id)
                        setBillRecordBillBookName(cacheBillBook.name)
                    }
                });
            }
        } else {
            // 编辑账单
            try {
                setBillRecordID(parseInt(id ?? '0'))
            }catch (e) {}
            NetworkBillRecordInfo(parseInt(id!)).then(response => {
                console.log(response)
                setPageState(LoadState.Success)
                setBillRecordID(response.id)
                setBillRecordName(response.name)
                setBillRecordMoney(response.money.toString())
                setBillRecordRemark(response.remark)
                setBillRecordTime(response.time * 1000) // 接口返回的是秒，这里转成毫秒
                setBillRecordBillBookID(response.billbook_id)
                setBillRecordType(response.type)
                // 获取账本名称
                BillBookCache.getBillBook(response.billbook_id).then((billBook) => {
                    if (billBook) {
                        setBillRecordBillBookName(billBook.name)
                    }
                })
                // 获取账本标签
                const responseTagID = response.tags
                if (responseTagID.length > 0) {
                    BillTagCache.loadTags().then(async () => {
                        const responseTagModelList = new Array<BillTag>()
                        for (const loopTagID of responseTagID) {
                            const loopTag = await BillTagCache.loadTag(loopTagID)
                            if (loopTag) {
                                responseTagModelList.push(loopTag)
                            }
                        }
                        setBillRecordTag(responseTagModelList)
                    }).catch((e) => {
                        Toast.show('标签加载失败，请稍后重试')
                        console.log(e)
                    })
                }
            }).catch(error => {
                setPageState(LoadState.Fail)
                Toast.show({
                    icon: 'fail',
                    content: error.toString(),
                })
            })
        }
    }

    const onUpdateTagByIDS = async (tagIDS: number[]) => {
        Toast.show({
            content: '加载标签中',
            duration: 0,
        })

        try {
            await BillTagCache.loadTags()
            const tmpBillRecordTag = new Array<BillTag>()
            for (const loopTagID of tagIDS) {
                const loopTag = await BillTagCache.loadTag(loopTagID)
                if (loopTag === undefined) {
                    throw new Error('标签加载失败')
                }
                tmpBillRecordTag.push(loopTag)
            }
            Toast.clear()
            setBillRecordTag(tmpBillRecordTag)
        } catch (e) {
            console.error("标签加载失败", e)
            Toast.clear()
            Toast.show('标签加载失败，请稍后重试')
        }


    }

    useEffect(() => {
        actionInit()
    }, [])


    return (
        <div className={styles.container}>
            <AppBar
                title={isAddBillRecord ? "创建账单" : "编辑账单"}
            />

            {
                pageState === LoadState.Loading ? (
                    <Result
                        className={styles.result}
                        status={"waiting"}
                        title={"加载中"}
                    />
                ) : null
            }

            {
                pageState === LoadState.Fail ? (
                    <Result
                        className={styles.result}
                        status={"error"}
                        title={"加载失败"}
                        description={(
                            <Button
                                block
                                color="primary"
                                onClick={() => {
                                    actionInit()
                                }}
                            >
                                重试
                            </Button>

                        )}
                    />
                ) : null
            }

            {
                pageState === LoadState.Success ? (
                    <div className={styles.containerBody}>
                        <Form
                            className={styles.form}
                            layout="vertical"
                            mode="card"
                            footer={(
                                <div className={styles.formFooter}>
                                    { !isAddBillRecord ? (
                                        <Button
                                            block
                                            color="danger"
                                            className={styles.btnDelete}
                                            onClick={actionOnDeleteClick}
                                        >
                                            删除账单
                                        </Button>
                                    ) : null }

                                    <Button
                                        block
                                        color="primary"
                                        loading={isRequesting}
                                        className={styles.btnConfirm}
                                        onClick={() => {
                                            if (isAddBillRecord) {
                                                actionOnAddClick()
                                            } else {
                                                actionOnEditClick()
                                            }
                                        }}
                                    >
                                        { isAddBillRecord ? '记录' : '保存' }
                                    </Button>
                                </div>
                            )}
                        >
                            {
                                isAddBillRecord ? (
                                    <Form.Item label='所属账本' rules={[{ required: true }]} onClick={() => {
                                        setIsShowBillBookPicker(true)
                                    }}>
                                        { billRecordBillBookName }
                                        <BillBookPicker
                                            isShow={isShowBillBookPicker}
                                            onClose={() => { setIsShowBillBookPicker(false) }}
                                            onConfirm={(billBook) => {
                                                console.log('pick', billBook)
                                                if (billBook) {
                                                    setBillRecordBillBookID(billBook.id)
                                                    setBillRecordBillBookName(billBook.name)
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                ) : null
                            }

                            <Form.Item label='账单名称' rules={[{ required: true }]}>
                                <Input
                                    placeholder='请输入账单名称'
                                    maxLength={32}
                                    value={billRecordName}
                                    onChange={(val) => { setBillRecordName(val) }}
                                />
                            </Form.Item>

                            <Form.Item label='账单类型' rules={[{ required: true }]}>
                                <Radio.Group value={billRecordType}>
                                    <Space style={{ '--gap': '24px' }}>
                                        <Radio value={NetworkBillRecordType.OutCome} onClick={() => setBillRecordType(NetworkBillRecordType.OutCome)}>支出</Radio>
                                        <Radio value={NetworkBillRecordType.InCome} onClick={() => setBillRecordType(NetworkBillRecordType.InCome)}>收入</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item label='交易金额'>
                                <VirtualInput
                                    clearable
                                    placeholder={'请输入金额'}
                                    value={billRecordMoney.toString()}
                                    keyboard={(
                                        <NumberKeyboard
                                            customKey={'.'}
                                            onInput={(val) => {
                                                if (billRecordMoney.toString() === '0') {
                                                    setBillRecordMoney(val)
                                                } else {
                                                    setBillRecordMoney(`${billRecordMoney}${val}`)
                                                }
                                            }}
                                            onDelete={() => {
                                                if (billRecordMoney.length <= 0) {
                                                    // do nothing
                                                } else if (billRecordMoney.length === 1) {
                                                    setBillRecordMoney('0')
                                                } else {
                                                    setBillRecordMoney(billRecordMoney.slice(0, -1))
                                                }
                                            }}
                                            onConfirm={() => {
                                                Toast.show('confirm')
                                            }}
                                        />
                                    )}
                                />
                            </Form.Item>

                            <Form.Item label='交易时间'>
                                <Space>
                                    <span
                                        className={styles.formTimeDate}
                                        onClick={() => {
                                            setIsShowDatePicker(true)
                                        }}
                                    >
                                        {TimestampTo_YYYYMMDD(billRecordTime)}
                                    </span>
                                    <span
                                        className={styles.formTimeTime}
                                        onClick={() => {
                                            setIsShowTimePicker(true)
                                        }}
                                    >
                                        {TimestampTo_HHMMSS(billRecordTime)}
                                    </span>

                                    <DatePicker
                                        visible={isShowDatePicker}
                                        onClose={() => { setIsShowDatePicker(false) }}
                                        defaultValue={new Date(billRecordTime)}
                                        closeOnMaskClick
                                        mouseWheel
                                        onConfirm={(val) => {
                                            setIsShowDatePicker(false)
                                            let tmpDate = new Date(billRecordTime)
                                            tmpDate.setFullYear(val.getFullYear())
                                            tmpDate.setMonth(val.getMonth())
                                            tmpDate.setDate(val.getDate())
                                            setBillRecordTime(tmpDate.getTime())
                                        }}
                                    />

                                    <TimePicker
                                        isShow={isShowTimePicker}
                                        onClosed={() => setIsShowTimePicker(false)}
                                        onConfirm={(hour, minute, second) => {
                                            console.log(hour, minute, second)
                                            setIsShowTimePicker(false)
                                            let tmpDate = new Date(billRecordTime)
                                            tmpDate.setHours(hour)
                                            tmpDate.setMinutes(minute)
                                            if (second !== undefined) {
                                                tmpDate.setSeconds(second)
                                            }
                                            setBillRecordTime(tmpDate.getTime())
                                        }}
                                    />
                                </Space>
                            </Form.Item>

                            <Form.Item label='标签' onClick={() => {
                                setIsShowBillTagPicker(true)
                            }}>
                                <Space>
                                    { billRecordTag && billRecordTag.length > 0 ? (
                                        billRecordTag.map(tag => {
                                            return (
                                                <Tag
                                                    type='fill'
                                                    size='normal'
                                                    key={`bill_record_add_edit_tag_${tag.id}`}
                                                    color={BillTagCache.getTagColor(tag.id)}
                                                >
                                                    { tag.name }
                                                </Tag>

                                            )
                                        })
                                    ) : <span style={{ color:'#ccc' }}>请选择标签</span> }
                                </Space>
                                <BillTagPicker
                                    isVisible={isShowBillTagPicker}
                                    defaultTag={billRecordTag.map((tag) => tag.id)}
                                    onClose={() => { setIsShowBillTagPicker(false) }}
                                    onConfirm={(tagID) => onUpdateTagByIDS(tagID)}
                                />
                            </Form.Item>

                            <Form.Item label='备注'>
                                <TextArea
                                    placeholder='请输入备注'
                                    maxLength={500}
                                    rows={5}
                                    showCount
                                    value={billRecordRemark}
                                    onChange={(val) => { setBillRecordRemark(val) }}
                                />
                            </Form.Item>
                        </Form>

                        {
                            billRecordID ? (
                                <span className={styles.fieldHint}>{`账单ID : ${billRecordID}`}</span>
                            ) : null
                        }

                        {
                            !isAddBillRecord && billRecordBillBookID ? (
                                <span className={styles.fieldHint}>{`所属账本 : ${billRecordBillBookName}`}</span>
                            ) : null
                        }

                    </div>
                ) : null
            }
        </div>
    );
}


export default BillBookAddEdit
