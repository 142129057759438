import React, {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";

import KV from "../../module/kv/kv";
import {KVUserToken, KVUserTokenAuthTime, KVUserTokenExpireTime, KVUserUserID} from "../../module/kv/kv_user_def";
import styles from "./index.module.css";
import AppBar, {AppBarNavIcon} from "../../compoment/AppBar";
import SectionTitle from "./compoment/SectionTitle";
import {Dialog, Divider, DotLoading, Toast} from "antd-mobile";
import {NetworkLoginVerify, NetworkLogout} from "../../module/network/network_login";
import PopupUserInfo from "./compoment/PopupUserInfo";
import {
    NetworkBillRecordList,
} from "../../module/network/network_billrecord";
import Billbook from "./compoment/Billbook";
import BillRecord from "./compoment/BillRecord";
import BillBookAdd from "./compoment/BillbookAdd";
import BillRecordAdd from "./compoment/BillRecordAdd";
import BillBookCache from "../../module/cache/billbook_cache";
import {sleep} from "antd-mobile/es/utils/sleep";
import Router from "../../route/router";
import useIndexPageSate from "./state";
import EventCenter from "../../module/event/event_center";
import {EventBillRecordAdd, EventBillRecordEdit} from "../../module/event/events_bill_record";


function Index(): React.JSX.Element {
    const navigate = useNavigate();

    // 页面内状态
    const [isShowPopupUserInfo, setIsShowPopupUserInfo] = useState(false)
    const [isRefreshingBillBook, setIsRefreshingBillBook] = useState(false)
    const [isRefreshingBillRecord, setIsRefreshingBillRecord] = useState(false)
    const [isLoadingMoreBillRecord, setIsLoadingMoreBillRecord] = useState(false)
    const [billRecordPage, setBillRecordPage] = useState(1)
    // 页面的全局状态
    const store = useIndexPageSate();
    const storeIsInit = useIndexPageSate(s => s.isInit);
    const storeSelectedBillBookID = useIndexPageSate(s => s.selectedBillBookID);
    const storeHasMoreBillRecord = useIndexPageSate(s => s.hasMoreBillRecord);
    const storeBillBookList = useIndexPageSate(s => s.billBookList);
    const storeBillRecordList = useIndexPageSate(s => s.billRecordList);
    const storeIsBillRecordUpdate = useIndexPageSate(s => s.isBillRecordUpdate);

    const actionLoadBillBook = (forceRefresh: boolean) : Promise<void> =>  {
        setIsRefreshingBillBook(true)
        return BillBookCache.getBillBookList(forceRefresh).then((response) => {
            console.log('index 账本加载成功', response)
            setIsRefreshingBillBook(false)
            store.setBillBookList(response)
        }).catch((error) => {
            Toast.show(`账本加载失败, ${error}`)
            setIsRefreshingBillBook(false)
        });
    }

    const actionLoadBillRecord = (billBookID: number, isRefreshing: boolean) : Promise<void> => {
        if (isRefreshing) {
            setIsRefreshingBillRecord(true)
        } else {
            setIsLoadingMoreBillRecord(true)
        }
        const requestPage = isRefreshing ? 1 : billRecordPage + 1
        const requestBillBookID = billBookID
        const requestPageSize = 20
        return NetworkBillRecordList({
            billbook_id: requestBillBookID,
            pageSize: requestPageSize,
            page: requestPage,
        }).then((response) => {
            if (isRefreshing) {
                setIsRefreshingBillRecord(false)
            } else {
                setIsLoadingMoreBillRecord(false)
            }
            store.setHasMoreBillRecord(response.length >= requestPageSize)
            setBillRecordPage(requestPage)
            store.setBillRecordList( isRefreshing ? response : storeBillRecordList.concat(response))
        }).catch((error) => {
            Toast.show(`账单加载失败, ${error}`)
            if (isRefreshing) {
                setIsRefreshingBillRecord(false)
            } else {
                setIsLoadingMoreBillRecord(false)
            }
        });
    }

    /* 如果没有登录，则跳转到登录页面 */
    useEffect(() => {
        // 事件检查
        if (storeIsBillRecordUpdate) {
            store.setIsBillRecordUpdate(false)
            // 触发重新加载
            setTimeout(() => { actionLoadBillRecord(storeSelectedBillBookID, true) }, 300)
        }
        // 防止重复初始化
        if (storeIsInit) {
            return;
        }
        store.setIsInit(true)
        // 注册事件通知
        EventCenter.addEventListener(EventBillRecordAdd.tag, (event) => {
            store.setIsBillRecordUpdate(true)
        })
        EventCenter.addEventListener(EventBillRecordEdit.tag, () => {
            console.log('收到账单编辑事件')
            store.setIsBillRecordUpdate(true)
        })
        // 判断登录状态
        const kvToken = KV.get(KVUserToken)
        const kvUserID = KV.get(KVUserUserID)
        const kvTokenExpireTime = KV.get(KVUserTokenExpireTime)
        const kvTokenAuthTime = KV.get(KVUserTokenAuthTime)
        const currentTime = Date.now()
        if (!kvToken.length) {
            Toast.show('请先登录')
            Router.replaceLogin(navigate)
            return
        }
        if (!kvUserID) {
            Toast.show('请先登录')
            Router.replaceLogin(navigate)
            return
        }
        if (kvTokenExpireTime < currentTime) {
            Toast.show('登录已过期，请重新登录')
            Router.replaceLogin(navigate)
            return
        }
        if (currentTime - kvTokenAuthTime < 1000 * 60 * 60 * 24) {
            // 一天以内不用重新鉴权
            console.log('无需刷新鉴权')
        } else {
            // 静默鉴权
            NetworkLoginVerify().then((response) => {
                console.log('静默鉴权成功')
                console.log(response)
            }).catch((error) => {
                console.log('静默鉴权失败')
                console.log(error)
            })
        }
        // 请求数据
        actionLoadBillBook(false);
        actionLoadBillRecord(0, true);
    }, [])

    /* 点击了退出登录 */
    const onTapLogout = () => {
        // 网络请求
        NetworkLogout().catch((error) => {
            console.log('退出登录请求失败, error: ', error)
        }).then(() => {
            console.log('退出登录成功')
            Router.replaceLogin(navigate)
        })
    }

    return (
        <div className={styles.container}>
            <AppBar
                title="橘子记账"
                navIcon={AppBarNavIcon.User}
                onTapNav={() => {
                    setIsShowPopupUserInfo(true)
                }}
            />
            <div className={styles.containerBody}>
                <SectionTitle
                    name={'账本'}
                    isLoading={isRefreshingBillBook}
                    onTapLoading={() => {
                        actionLoadBillBook(true)
                    }}
                />
                <div className={styles.containerBillList}>
                    {
                        storeBillBookList.map((billbook) => {
                            return (
                                <Billbook
                                    key={`index_billbook_${billbook.id}`}
                                    isSelected={billbook.id === storeSelectedBillBookID}
                                    billBook={billbook}
                                    onTap={() => {
                                        if (billbook.id === storeSelectedBillBookID) {
                                            store.setSelectedBillBookID(0)
                                            actionLoadBillRecord(0, true)
                                        } else {
                                            store.setSelectedBillBookID(billbook.id)
                                            actionLoadBillRecord(billbook.id, true)
                                        }
                                    }}
                                    onLongTap={() => {
                                        Router.pushEditBillBook(navigate, billbook.id)
                                    }}
                                />
                            )
                        })
                    }
                    <BillBookAdd
                        onTap={() => {
                            Router.pushAddBillBook(navigate)
                        }}
                    />
                </div>

                <SectionTitle
                    name={'账单'}
                    isLoading={isRefreshingBillRecord}
                    onTapLoading={() => {
                        actionLoadBillRecord(storeSelectedBillBookID,true)
                    }}
                />
                <div className={styles.containerRecordList}>
                    <BillRecordAdd
                        onTap={() => {
                            Router.pushAddBillRecord(navigate, {
                                defaultBillBookID: storeSelectedBillBookID,
                            })
                        }}
                    />
                    {
                        storeBillRecordList.map((billrecord) => {
                            return (
                                <BillRecord
                                    key={`index_billrecord_${billrecord.id}`}
                                    billRecord={billrecord}
                                    onTap={() => {
                                        Router.pushEditBillRecord(navigate, billrecord.id)
                                    }}
                                />
                            )
                        })
                    }
                    {
                        isRefreshingBillRecord ? null : (
                            storeHasMoreBillRecord ? (
                                isLoadingMoreBillRecord ? (
                                    <div className={styles.recordListLoadMore}>
                                        <DotLoading color="primary"/>
                                    </div>
                                ) : (
                                    <div
                                        className={styles.recordListLoadMore}
                                        onClick={() => {
                                            actionLoadBillRecord(storeSelectedBillBookID, false)
                                        }}
                                    >
                                        点击加载更多
                                    </div>
                                )
                            ) : <Divider>没有更多记录啦</Divider>
                        )
                    }

                    {/*<InfiniteScroll*/}
                    {/*    hasMore={isHasMoreBillRecord}*/}
                    {/*    loadMore={() => actionLoadBillRecord(false)}*/}
                    {/*/>*/}
                </div>
            </div>

            <PopupUserInfo
                isShow={isShowPopupUserInfo}
                onSummary={() => {
                    Router.pushSummary(navigate)
                }}
                onManageTag={() => {
                    Router.pushBillTagManager(navigate)
                }}
                onDismiss={() => {
                    setIsShowPopupUserInfo(false)
                }}
                onClearCache={() => {
                    Dialog.confirm({
                        content: '确定要清除缓存吗？',
                        onConfirm: async() => {
                            BillBookCache.clearCache()
                            await sleep(500)
                            Toast.show('缓存已清除')
                        },
                    })
                }}
                onLogout={onTapLogout}
            />
        </div>
    )
}


export default Index;
