import React from "react";

import styles from "./index.module.css";

import {DotLoading} from "antd-mobile";
import { UndoOutline } from "antd-mobile-icons"

interface Prop {
    name: string,
    isLoading: boolean,
    onTapLoading: () => void,
}


const SectionTitle = (prop:Prop) : React.JSX.Element => {
    const {name, isLoading, onTapLoading} = prop;
    return (
        <div className={styles.container}>
            <div className={styles.indicator} />
            <span className={styles.title}>{name}</span>
            {
                isLoading
                    ? <DotLoading color={"primary"} />
                    : <UndoOutline className={styles.iconLoading} onClick={onTapLoading} />
            }
        </div>
    );
}


export default SectionTitle;

