import React, {useEffect, useState} from "react";

import styles from './index.module.css'
import AppBar from "../../compoment/AppBar";
import {Button, Dialog, DotLoading, Form, Input, Space, Toast} from "antd-mobile";
import LoadState from "../../definition/load_state";
import ColorPicker from "../../compoment/ColorPicker";
import {NetworkBillTagCreate, NetworkBillTagDelete, NetworkBillTagEdit} from "../../module/network/network_billtag";
import Router from "../../route/router";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import URLSearchParamsExtensions from "../../utils/url_search_params_extensions";
import BillTagCache from "../../module/cache/billtag_cache";
import EventCenter from "../../module/event/event_center";
import {EventBillTagAdd, EventBillTagEdit} from "../../module/event/events_bill_tag";

const BillTagAddEdit = () : React.JSX.Element => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm()

    // 读取并解析初始参数
    let tmpParamsIsEditTag = false
    let tmpParamsTagID = 0
    const queryParams = new URLSearchParams(location.search)
    const queryParamsParentID = URLSearchParamsExtensions.getInt(queryParams, 'parent_id')
    if (id !== undefined) {
        const idNumber = parseInt(id)
        // 如果是数字且大于0，则代表是编辑标签
        if (!isNaN(idNumber) && idNumber > 0) {
            tmpParamsIsEditTag = true
            tmpParamsTagID = idNumber
        }
    }

    const [pageState, setPageState] = useState(LoadState.Loading)
    const [isShowColorPicker, setIsShowColorPicker] = useState(false)
    const [isAddingEditingTag, setIsAddingEditingTag] = useState(false)
    const [isDeletingTag, setIsDeletingTag] = useState(false)
    const [isEditTag] = useState(tmpParamsIsEditTag)
    const [isTagGroup, setIsTagGroup] = useState(false)

    const [tagColor, setTagColor] = useState('')
    const [tagName, setTagName] = useState('')
    const [tagID] = useState(tmpParamsTagID)
    const [tagParentID, setTagParentID] = useState(0)
    const [tagParentName, setTagParentName] = useState('')

    const eventInit = () => {
        // 显示为加载中
        setPageState(LoadState.Loading)
        // 加载
        if (isEditTag) {
            /* 编辑标签 */
            BillTagCache.loadTag(tagID).then(tag => {
                if (tag === undefined) {
                    throw new Error('标签不存在')
                }
                setTagName(tag.name)
                form.setFieldsValue({ name: tag.name }) // ANTD 界面了复制，后面考虑把 Antd 的 From 换成自己写的
                setTagColor(BillTagCache.getTagColor(tagID))
                return BillTagCache.getParentTag(tagID)
            }).then((parentTag) => {
                setPageState(LoadState.Success)
                if (parentTag) {
                    setTagParentID(parentTag.id)
                    setTagParentName(parentTag.name)
                } else {
                    setIsTagGroup(true)
                }
            }).catch(err => {
                setPageState(LoadState.Fail)
                Toast.show(`标签加载失败, ${err}`)
            })
        } else {
            /* 新增标签 */
            Promise.resolve().then(() => {
                if (queryParamsParentID > 0) {
                    BillTagCache.loadTag(queryParamsParentID).then((tag) => {
                        if (tag === undefined) {
                            throw new Error('标签组不存在')
                        }
                        setTagParentID(tag.id)
                        setTagParentName(tag.name)
                    })
                } else {
                    setIsTagGroup(true)
                }
            }).then(() => {
                setPageState(LoadState.Success)
            }).catch(err => {
                setPageState(LoadState.Fail)
                console.error('新增标签加载失败, ', err)
            })
        }
    }

    const actionOnClickColor = () => {
        setIsShowColorPicker(true)
    }

    const actionOnClickConfirm = () => {
        if (!tagName) {
            Toast.show('请填写标签名称')
            return
        }
        if (isEditTag) {
            setIsAddingEditingTag(true)
            NetworkBillTagEdit({
                tagID: tagID,
                name: tagName,
                color: tagColor,
            }).then((res) => {
                setIsAddingEditingTag(false)
                Toast.show('编辑成功')
                EventCenter.notify(new EventBillTagEdit())
                Router.goBack(navigate)
            }).catch(err => {
                setIsAddingEditingTag(false)
                Toast.show(`编辑失败, ${err}`)
            })
        } else {
            setIsAddingEditingTag(true)
            NetworkBillTagCreate({
                name: tagName,
                color: tagColor,
                parent_id: tagParentID
            }).then((res) => {
                setIsAddingEditingTag(false)
                Toast.show('创建成功')
                EventCenter.notify(new EventBillTagAdd())
                Router.goBack(navigate)
            }).catch(err => {
                Toast.show(`创建失败: ${err}`)
                setIsAddingEditingTag(false)
            })
        }
    }

    const actionOnClickDelete = () => {
        Dialog.show({
            content:  '您确定要删除吗？删除后标签不能恢复噢',
            closeOnAction: true,
            closeOnMaskClick: true,
            actions: [[
                {
                    key: 'cancel',
                    text: '取消',
                }, {
                    key: 'delete',
                    text: '删除',
                    danger: true,
                }
            ]],
            onAction: (action) => {
                if (action.key === 'delete') {
                    setIsDeletingTag(true)
                    NetworkBillTagDelete(tagID).then(() => {
                        setIsDeletingTag(false)
                        Toast.show('删除成功')
                        Router.goBack(navigate)
                    }).catch(err => {
                        console.error(err)
                        setIsDeletingTag(false);
                        Toast.show(`删除失败, ${err}`)
                    })
                }
            },
        })
    }

    useEffect(() => {
        eventInit()
    }, []);

    return (
        <div className={styles.container}>
            <AppBar title={`${isEditTag ? '编辑' : '创建'}${isTagGroup ? '标签组' : '标签'}`}/>
            <div className={styles.containerBody}>
                <Form
                    className={styles.containerForm}
                    form={form}
                    footer={(
                        <Space direction="vertical" className={styles.containerAction}>
                            {tagParentID ? <span>所属标签组 : {tagParentName}</span> : null}
                            {isEditTag ? <Button block color='danger' onClick={actionOnClickDelete}>{isDeletingTag ? <DotLoading /> : '删除'}</Button> : null }
                            <Button block color='primary' onClick={actionOnClickConfirm} loading={isAddingEditingTag}>{ isEditTag ? '编辑' : '创建'}</Button>
                        </Space>
                    )}
                >

                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input
                            placeholder='请输入标签名称'
                            value={tagName}
                            onChange={(val) => {
                                setTagName(val)
                            }}
                        />
                    </Form.Item>

                    <Form.Item label='颜色' onClick={actionOnClickColor}>
                        { tagColor ? <div className={styles.colorPreview} style={{ backgroundColor: tagColor }}/> : <span>自动适应</span> }
                    </Form.Item>

                </Form>
            </div>

            <ColorPicker
                isShow={isShowColorPicker}
                onClose={() => setIsShowColorPicker(false)}
                color={tagColor}
                onConfirm={(color) => {
                    setIsShowColorPicker(false)
                    setTagColor(color)
                }}
            />
        </div>
    )
}

export default BillTagAddEdit
