
import Network, {NetworkRequestMethod} from "./network";
import {SimpleError} from "../../definition/error";


/********************************************************************************************************************** 请求账本列表 */

interface NetworkBillBook {
    id: number,
    create_time: number,
    name: string,
}

const NetworkBillBookList = () : Promise<Array<NetworkBillBook>> => {
    return Network.request({
        method: NetworkRequestMethod.Get,
        path: '/api/billbook/list',
    }).then((response) => {
        console.log(response)
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data
    }).then(response => {
        console.log(response)
        return response as Array<NetworkBillBook>
    })
}

/********************************************************************************************************************** 创建账本 */

const NetworkBillBookCreate = (name: string): Promise<NetworkBillBook> => {
    return Network.request<NetworkBillBook>({
        method: NetworkRequestMethod.Put,
        path: '/api/billbook',
        data: {
            name: name,
        }
    }).then(response => {
        console.log(response)
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkBillBook
    });
}

/********************************************************************************************************************** 查看账本信息 */

const NetworkBillBookInfo = (id: number): Promise<NetworkBillBook> => {
    return Network.request<NetworkBillBook>({
        method: NetworkRequestMethod.Get,
        path: `/api/billbook/${id}`,
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkBillBook
    })
}

/********************************************************************************************************************** 更新账本信息 */

interface NetworkBillBookUpdateProp {
    id: number,
    name?: string,
}

const NetworkBillBookUpadate = (prop: NetworkBillBookUpdateProp): Promise<NetworkBillBook> => {
    const {id, name} = prop
    return Network.request<NetworkBillBook>({
        method: NetworkRequestMethod.Post,
        path: `/api/billbook/${id}`,
        data: {
            name: name,
        }
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkBillBook
    })
}

/********************************************************************************************************************** 删除账本信息 */

const NetworkBillBookDelete = (id: number): Promise<void> => {
    return Network.request<any>({
        method: NetworkRequestMethod.Delete,
        path: `/api/billbook/${id}`,
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return
    })
}


export type {
    NetworkBillBook,
};

export {
    NetworkBillBookList,
    NetworkBillBookCreate,
    NetworkBillBookInfo,
    NetworkBillBookUpadate,
    NetworkBillBookDelete,
};
