
enum Durations {
    Today,
    Yesterday,
    TheDayBeforeYesterday,
    ThisWeek,
    LastWeek,
    ThisMonth,
    LastMonth,
    Custom
}

interface SummaryChartTotal {
    money: number,
    money_income: number,
    money_outcome: number,
    count: number
}

interface SummaryChartDayItem {
    time: number,
    money: number,
    count: number,
}

interface SummaryChartDay {
    data: SummaryChartDayItem[],
}


export type {
    SummaryChartTotal,
    SummaryChartDay,
    SummaryChartDayItem,
}

export {
    Durations,
}
