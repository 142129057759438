import React, {useEffect, useRef, useState} from "react";

import styles from "./index.module.css"
import AppBar from "../../compoment/AppBar";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Dialog, Form, Input, Result, Toast} from "antd-mobile";
import {
    NetworkBillBookCreate, NetworkBillBookDelete,
    NetworkBillBookInfo,
    NetworkBillBookUpadate
} from "../../module/network/network_billbook";
import LoadState from "../../definition/load_state";
import {TimestampTo_YYYYMMDD_HHMMSS} from "../../utils/format_utils";
import {DialogShowHandler} from "antd-mobile/es/components/dialog";
import Router from "../../route/router";

const BillBookAddEdit = (): React.JSX.Element => {
    const { id } = useParams()
    const navigate = useNavigate()

    const [isAddBillBook] = useState(id === undefined)
    const [isRequesting, setIsRequesting] = useState(false)
    const [billBookName, setBillBookName] = useState('')
    const [billBookID, setBillBookID] = useState<number>(0)
    const [billBookCreateTime, setBillBookCreateTime] = useState(0)
    const [pageState, setPageState] = useState(LoadState.Loading)

    const actionOnAddClick = () => {
        if (isRequesting) {
            return
        }
        if (billBookName.length <= 0) {
            Toast.show('账本名称不能为空噢~')
            return;
        }
        // 状态修改
        setIsRequesting(true)
        // 进行请求
        NetworkBillBookCreate(billBookName).then(() => {
            Toast.show({
                icon: "success",
                content: '账本创建好啦，快来记下第一笔帐吧'
            })
            setIsRequesting(false)
            Router.goBack(navigate)
        }).catch((e) => {
            Toast.show({
                icon: 'fail',
                content: e.toString(),
            })
            setIsRequesting(false)
        })
    }

    const actionOnEditClick = () => {
        if (isRequesting) {
            return
        }
        if (billBookName.length <= 0) {
            Toast.show('账本名称不能为空噢~')
            return;
        }
        // 状态修改
        setIsRequesting(true)
        // 进行请求
        NetworkBillBookUpadate({
            id: billBookID,
            name: billBookName,
        }).then(result => {
            Toast.show({
                icon: "success",
                content: '账本修改成功'
            })
            // 返回上一个界面
            Router.goBack(navigate)
        }).catch(error => {
            console.log(error)
            Toast.show({
                icon: 'fail',
                content: error.toString(),
            })
        })
    }

    const actionOnDeleteClick = () => {
        // 弹窗确定
        Dialog.confirm({
            title: '删除账本',
            content: '您确定要删除这个账本吗？所有数据都会被永久删除哦！',
            confirmText: (<span className={styles.textDanger}>删除</span>),
            onConfirm: () => {
                NetworkBillBookDelete(billBookID).then(() => {
                    Toast.show({
                        icon: 'success',
                        content: '账本删除成功'
                    })
                    Router.goBack(navigate)
                }).catch((error) => {
                    console.log(error)
                    Toast.show({
                        icon: 'fail',
                        content: error.toString(),
                    })
                })
            }
        })
    }

    const actionInit = () => {
        // 加载中
        setPageState(LoadState.Loading)
        // 加载
        if (isAddBillBook) {
            // 新增账本
            setPageState(LoadState.Success)
        } else {
            // 编辑账本
            try {
                setBillBookID(parseInt(id ?? '0'))
            }catch (e) {}
            NetworkBillBookInfo(parseInt(id!)).then(response => {
                setBillBookName(response.name)
                setBillBookID(response.id)
                setBillBookCreateTime(response.create_time)
                console.log(response.name, response.id, response.create_time)
                setPageState(LoadState.Success)
            }).catch(error => {
                setPageState(LoadState.Fail)
                Toast.show({
                    icon: 'fail',
                    content: error.toString(),
                })
            })
        }
    }

    useEffect(() => {
        actionInit()
    }, [])

    return (
        <div className={styles.container}>
            <AppBar
                title={isAddBillBook ? "创建账本" : "编辑账本"}
            />


            {
                pageState === LoadState.Loading ? (
                    <Result
                        className={styles.result}
                        status={"waiting"}
                        title={"加载中"}
                    />
                ) : null
            }

            {
                pageState === LoadState.Fail ? (
                    <Result
                        className={styles.result}
                        status={"error"}
                        title={"加载失败"}
                        description={(
                            <Button
                                block
                                color="primary"
                                onClick={() => {
                                    actionInit()
                                }}
                            >
                                重试
                            </Button>

                        )}
                    />
                ) : null
            }

            {
                pageState === LoadState.Success ? (
                    <div className={styles.containerBody}>
                        <Form
                            layout="vertical"
                            mode="card"
                            footer={(
                                <div className={styles.formFooter}>
                                    { !isAddBillBook ? (
                                        <Button
                                            block
                                            color="danger"
                                            className={styles.btnDelete}
                                            onClick={actionOnDeleteClick}
                                        >
                                            删除账本
                                        </Button>
                                    ) : null }

                                    <Button
                                        block
                                        color="primary"
                                        loading={isRequesting}
                                        className={styles.btnConfirm}
                                        onClick={() => {
                                            if (isAddBillBook) {
                                                actionOnAddClick()
                                            } else {
                                                actionOnEditClick()
                                            }
                                        }}
                                    >
                                        { isAddBillBook ? '创建' : '保存' }
                                    </Button>
                                </div>
                            )}
                        >
                            <Form.Item label='账本名称' rules={[{ required: true }]}>
                                <Input
                                    placeholder='请输入账本名称'
                                    maxLength={32}
                                    value={billBookName}
                                    onChange={(val) => { setBillBookName(val) }}
                                />
                            </Form.Item>
                            {
                                billBookCreateTime ? (
                                    <Form.Item label='账本创建时间'>
                                        <span className={styles.textNotEditable}>{TimestampTo_YYYYMMDD_HHMMSS(billBookCreateTime)}</span>
                                    </Form.Item>
                                ) : null
                            }
                        </Form>

                        {
                            billBookID ? (
                                <span className={styles.fieldHint}>{`账本ID : ${billBookID}`}</span>
                            ) : null
                        }

                    </div>
                ) : null
            }
        </div>
    );
}


export default BillBookAddEdit
