
const URLSearchParamsExtensions = {

    getInt : (params: URLSearchParams, key: string, defaultValue: number = 0): number => {
        const value = params.get(key)
        if (value === null) return defaultValue
        let result = defaultValue
        try {
            result = parseInt(value)
        } catch (error) {
            console.warn('URLSearchParamsExtensions', `getInt(key=${key})` )
        }
        return result
    }

}


export default URLSearchParamsExtensions;
