import React from "react";

import styles from "./index.module.css";
import {NetworkBillBook} from "../../../../module/network/network_billbook";
import {useLongPress} from "use-long-press";

interface Prop {
    isSelected: boolean;
    billBook: NetworkBillBook,
    onTap: () => void;
    onLongTap: () => void;
}

const Billbook = (prop: Prop): React.JSX.Element => {
    const { isSelected, billBook, onTap, onLongTap } = prop;

    const bind = useLongPress(onLongTap)

    return (
        <div
            className={`${styles.container} ${isSelected ? styles.selected : ''}`}
            onClick={onTap}
            {...bind()}
        >
            { billBook.name }
        </div>
    );
}


export default Billbook;
