import React, {useEffect, useState} from "react";
import {AutoCenter, Popup, Space} from "antd-mobile";
import { CheckOutline } from 'antd-mobile-icons'

import styles from './index.module.css'

interface ColorItem {
    name: string;
    hex: string;
    displayHex?: string;
}

interface ColorGroup {
    name?: string;
    items: Array<ColorItem>;
}


interface ColorBlockProp {
    name?: string;
    color: string;
    value: string;
    isSelect: boolean;
    onClick?: (color: string) => void;
}

const ColorBlock = (prop: ColorBlockProp) : React.JSX.Element => {
    const { color, name, value, isSelect } = prop;
    return (
        <div
            className={styles.colorBlock}
            style={{
                transitionDuration: '150ms',
                animationDuration: '150ms',
                msTransitionDuration: '150ms',
                backgroundColor:color,
                border: isSelect ? '8px solid #ddd' : '0 solid #ddd',
                animationTimingFunction: 'linear',
                msTransitionTimingFunction: 'linear',
                transitionTimingFunction: 'linear',
                width: isSelect ? '48px' : '64px',
                height: isSelect ? '48px' : '64px',
            }}
            onClick={() => {
                if (prop.onClick) {
                    prop.onClick(value)
                }
            }}
        >
            { isSelect ? <CheckOutline fontSize={32} /> : null }
            {!isSelect ? <span className={styles.colorBlockName}>{name ?? color}</span> : null}
        </div>
    )
}


interface Props {
    isShow: boolean;
    onClose?: () => void;
    color?: string;
    onConfirm?: (color: string) => void;
}

const ColorPicker = (prop: Props): React.JSX.Element => {
    const { isShow, onClose, color, onConfirm } = prop;

    const [currentColor, setCurrentColor] = useState('')


    useEffect(() => {
        if (isShow) {
            let tmpColor = color?.toLowerCase() ?? ''
            if (tmpColor.length > 0 && !tmpColor.startsWith('#')) {
                tmpColor = '#' + tmpColor
            }
            setCurrentColor(tmpColor)
        }
    }, [isShow])

    const onClickConfirm = () => {
        if (onConfirm) {
            onConfirm(currentColor)
        }
        if (onClose) {
            onClose()
        }
    }

    const onClickCancel = () => {
        if (onClose) {
            onClose()
        }
    }

    // 配色方案参考：
    // https://www.chinavid.com/chinese-color.html
    const colorList : Array<ColorGroup> = [
        {
            items : [
                { name: '自动', hex: '', displayHex: '#000000' },
            ],
        },{
            name: '红',
            items : [
                { name: '朱砂', hex: '#ff461f' },
                { name: '火红', hex: '#ff2d51' },
                { name: '朱膘', hex: '#f36838' },
                { name: '妃色', hex: '#ed5736' },
                { name: '洋红', hex: '#ff4777' },
                { name: '品红', hex: '#f00056' },
                { name: '粉红', hex: '#ffb3a7' },
                { name: '桃红', hex: '#f47983' },
                // { name: '海棠红', hex: '#db5a6b' },
                // { name: '樱桃色', hex: '#c93756' },
                // { name: '酡颜', hex: '#f9906f' },
                // { name: '银红', hex: '#f05654' },
                // { name: '大红', hex: '#ff2121' },
                // { name: '石榴红', hex: '#f20c00' },
                // { name: '绯红', hex: '#c83c23' },
                // { name: '胭脂', hex: '#9d2933' },
                // { name: '朱红', hex: '#ff4c00' },
                // { name: '丹', hex: '#ff4e20' },
                // { name: '彤', hex: '#f35336' },
                // { name: '酡红', hex: '#dc3023' },
                // { name: '炎', hex: '#ff3300' },
                // { name: '茜色', hex: '#cb3a56' },
                // { name: '嫣红', hex: '#ef7a82' },
                // { name: '洋红', hex: '#ff0097' },
                // { name: '枣红', hex: '#c32136' },
                // { name: '殷红', hex: '#be002f' },
                // { name: '赫赤', hex: '#bf242a' },
                // { name: '赤', hex: '#c3272b' },
            ],
        },{
            name: '紫',
            items : [
                { name: '紫色', hex: '#8d4bbb' },
                { name: '黛紫', hex: '#574266' },
                { name: '绛紫', hex: '#8c4356' },
                { name: '紫酱', hex: '#815463' },
                { name: '酱紫', hex: '#815476' },
                { name: '紫棠', hex: '#56004f' },
                // { name: '青莲', hex: '#801dae' },
                // { name: '乌色', hex: '#725e82' },
                // { name: '雪青', hex: '#b0a4e3' },
                // { name: '丁香色', hex: '#cca4e3' },
            ],
        },{
            name: '灰',
            items : [
                { name: '墨灰', hex: '#758a99' },
                { name: '墨色', hex: '#50616d' },
                { name: '鸦青', hex: '#424c50' },
                { name: '黯', hex: '#41555d' },
                { name: '蟹壳青', hex: '#bbcdc5' },
                // { name: '花白', hex: '#c2ccd0' },
                // { name: '老银', hex: '#bacac6' },
                // { name: '灰色', hex: '#808080' },
                // { name: '苍色', hex: '#75878a' },
            ],
        },{
            name: '黑',
            items : [
                { name: '漆黑', hex: '#161823' },
                { name: '黑色', hex: '#000000' },
                // { name: '乌黑', hex: '#392f41' },
                // { name: '玄青', hex: '#3d3b4f' },
            ],
        },{
            name: '绿',
            items : [
                { name: '油绿', hex: '#00bc12' },
                { name: '葱青', hex: '#0eb83a' },
                { name: '青葱', hex: '#0aa344' },
                { name: '石绿', hex: '#16a951' },
                { name: '松柏绿', hex: '#21a675' },
                { name: '绿沈', hex: '#0c8918' },
                // { name: '绿色', hex: '#00e500' },
                // { name: '草绿', hex: '#40de5a' },
                // { name: '豆青', hex: '#96ce54' },
                // { name: '豆绿', hex: '#9ed048' },
                // { name: '葱绿', hex: '#9ed900' },
                // { name: '嫩绿', hex: '#9ed900' },
            ],
        },{
            name: '青',
            items : [
                { name: '青翠', hex: '#00e079' },
                { name: '青色', hex: '#00e09e' },
                { name: '翡翠色', hex: '#3de1ad' },
                { name: '碧绿', hex: '#2add9c' },
                { name: '玉色', hex: '#2edfa3' },
                { name: '缥', hex: '#7fecad' },
                // { name: '艾绿', hex: '#a4e2c6' },
                // { name: '水色', hex: '#88ada6' },
                // { name: '石青', hex: '#7bcfa6' },
                // { name: '碧色', hex: '#1bd1a5' },
                // { name: '青碧', hex: '#48c0a3' },
                // { name: '铜绿', hex: '#549688' },
            ],
        },{
            name: '橙',
            items : [
                { name: '橙色', hex: '#fa8c35' },
                { name: '橙黄', hex: '#ffa400' },
                { name: '杏黄', hex: '#ffa631' },
                { name: '杏红', hex: '#ff8c31' },
                // { name: '橘黄', hex: '#ff8936' },
                // { name: '橘红', hex: '#ff7500' },
                // { name: '琥珀', hex: '#ca6924' },
            ],
        },{
            name: '黄',
            items : [
                { name: '赤金', hex: '#f2be45' },
                { name: '缃色', hex: '#f0c239' },
                { name: '雄黄', hex: '#e9bb1d' },
                { name: '秋香色', hex: '#d9b611' },
                { name: '金色', hex: '#eedeb0' },
                // { name: '枯黄', hex: '#d3b17d' },
                // { name: '黄栌', hex: '#e29c45' },
                // { name: '鹅黄', hex: '#fff143' },
                // { name: '樱草色', hex: '#eaff56' },
            ],
        },{
            name: '棕',
            items : [
                { name: '乌金', hex: '#a78e44' },
                { name: '棕黄', hex: '#ae7000' },
                // { name: '昏黄', hex: '#c89b40' },
            ],
        },
    ]

    return (
        <Popup
            visible={isShow}
            onClose={onClose}
            onMaskClick={onClose}
        >
            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <span className={styles.headerCancel} onClick={onClickCancel}>取消</span>
                    <span className={styles.headerConfirm} onClick={onClickConfirm}>确定</span>
                </div>
            </div>

            <AutoCenter>
                <Space block wrap justify='center' align='center' className={styles.bodyContainer}>
                    {
                        colorList.map((group) => {
                            return (
                                <div
                                    key={`color_picker_group_${group.name}`}
                                    className={styles.colorGroupContainer}
                                >
                                    {group.name ? <span className={styles.colorGroupTitle}>{group.name}</span> : null}
                                    <Space className={styles.colorGroupItemSpace} block wrap justify='start' align='center'>
                                        {
                                            group.items.map((color) => {
                                                return (
                                                    <ColorBlock
                                                        key={`color_picker_item_${color.hex}`}
                                                        name={color.name}
                                                        color={color.displayHex ?? color.hex}
                                                        value={color.hex}
                                                        isSelect={currentColor === color.hex}
                                                        onClick={(color) => {
                                                            setCurrentColor(color)
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </Space>
                                </div>
                            )
                        })
                    }
                </Space>
            </AutoCenter>

        </Popup>
    )
}

export default ColorPicker
