
import KVItem from './kv_item'


const _SCOPE_CACHE = 'cache'


const KVCacheBillBook = new KVItem(_SCOPE_CACHE, 'bill_book', '')
const KVCacheBillBookUpdateTime = new KVItem(_SCOPE_CACHE, 'bill_book_update_time', 0)

const KVCacheBillTag = new KVItem(_SCOPE_CACHE, 'bill_tag', '')
const KVCacheBillTagUpdateTime = new KVItem(_SCOPE_CACHE, 'bill_tag_update_time', 0)


export {
    KVCacheBillBook,
    KVCacheBillBookUpdateTime,

    KVCacheBillTag,
    KVCacheBillTagUpdateTime,
}

