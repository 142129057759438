/********************************************************************************************************************** 请求账单列表 */
import Network, {NetworkRequestMethod} from "./network";
import {SimpleError} from "../../definition/error";

interface NetworkBillRecord {
    id: number,
    billbook_id: number,
    money: number,
    name: string,
    remark: string,
    time: number,
    type: number,
    tags: number[],
}

enum NetworkBillRecordType {
    OutCome = 0,
    InCome = 1,
}

interface NetworkBillRecordListProp {
    page: number,
    pageSize: number,
    billbook_id: number,
}

const NetworkBillRecordList = (prop: NetworkBillRecordListProp): Promise<Array<NetworkBillRecord>> => {
    return Network.request({
        method: NetworkRequestMethod.Get,
        path: '/api/billrecord/list',
        params: {
            page: prop.page,
            count: prop.pageSize,
            billbook_id: prop.billbook_id,
        },
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        console.log(response.data)
        return response.data as Array<NetworkBillRecord>
    })
}

/********************************************************************************************************************** 请求账单信息 */

const NetworkBillRecordInfo = (billRecordID: number): Promise<NetworkBillRecord> => {
    return Network.request({
        method: NetworkRequestMethod.Get,
        path: `/api/billrecord/${billRecordID}`,
        params: {
            id: billRecordID,
        },
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        console.log(response.data)
        return response.data as NetworkBillRecord
    })
}

/********************************************************************************************************************** 新建账单信息 */

interface NetworkBillRecordCreateProp {
    billBookID: number,
    name: string,
    money: number,
    time: number,
    remark?: string,
    type: number,
    tags?: Array<number>
}

const NetworkBillRecordCreate = (prop: NetworkBillRecordCreateProp) : Promise<NetworkBillRecord> => {
    return Network.request<NetworkBillRecord>({
        method: NetworkRequestMethod.Put,
        path: '/api/billrecord',
        data: {
            billbook_id: prop.billBookID,
            name: prop.name,
            money: prop.money,
            time: prop.time,
            remark: prop.remark ?? '',
            type: prop.type,
            tags: prop.tags,
        }
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkBillRecord
    })
}

/********************************************************************************************************************** 编辑账单信息 */

interface NetworkBillRecordUpdateProp {
    id: number,
    name?: string,
    money?: number,
    time?: number,
    remark?: string,
    type?: number,
    tags?: Array<number>|undefined,
}

const NetworkBillRecordUpdate = (prop: NetworkBillRecordUpdateProp) : Promise<NetworkBillRecord> => {
    const requestData: {[key:string]: any} = {};
    if (prop.name) requestData['name'] = prop.name;
    if (prop.money) requestData['money'] = prop.money;
    if (prop.time) requestData['time'] = prop.time;
    if (prop.remark) requestData['remark'] = prop.remark;
    if (prop.type !== undefined) requestData['type'] = prop.type;
    if (prop.tags !== undefined) requestData['tags'] = prop.tags;

    return Network.request<NetworkBillRecord>({
        method: NetworkRequestMethod.Post,
        path: `/api/billrecord/${prop.id}`,
        data: requestData,
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkBillRecord
    })
}

/********************************************************************************************************************** 删除账单信息 */

const NetworkBillRecordDelete = (billRecordID: number) : Promise<void> => {
    return Network.request<NetworkBillRecord>({
        method: NetworkRequestMethod.Delete,
        path: `/api/billrecord/${billRecordID}`,
    }).then(response => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return
    })
}

export type {
    NetworkBillRecord,
    NetworkBillRecordCreateProp,
    NetworkBillRecordUpdateProp,
};

export {
    NetworkBillRecordList,
    NetworkBillRecordInfo,
    NetworkBillRecordCreate,
    NetworkBillRecordUpdate,
    NetworkBillRecordDelete,

    NetworkBillRecordType,
};
