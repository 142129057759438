

import KVItem from './kv_item'


const _SCOPE_SERVER = 'server'


const KVServerAddress = new KVItem(_SCOPE_SERVER, 'address', 'https://bill.creepersan.com')


export {
    KVServerAddress,
}

