import React from "react";
import { AddOutline } from "antd-mobile-icons";

import styles from './index.module.css';

interface Prop {
    onTap: () => void,
}

const BillRecordAdd = (prop: Prop) : React.JSX.Element => {
    const { onTap } = prop

    return (
        <div className={styles.container} onClick={onTap}>
            <AddOutline className={styles.icon} />
            <span className={styles.text}>添加账单</span>
        </div>
    );
}

export default BillRecordAdd;
