import BaseEvent from "./base_event";

class EventBillRecordAdd extends BaseEvent {
    static readonly tag: string = "EventBillRecordAdd";
}

class EventBillRecordEdit extends BaseEvent {
    static readonly tag: string = "EventBillRecordEdit";
}


export {
    EventBillRecordAdd,
    EventBillRecordEdit
}
