import axios, {AxiosHeaders} from "axios";
import KV from "../kv/kv";
import {KVServerAddress} from "../kv/kv_server_def";
import {DeviceMachineCode, DeviceName, DevicePlatform} from "../device_info/device_info";
import {KVUserToken} from "../kv/kv_user_def";

class NetworkManager {

    /***************************************** 单例定义 *****************************************/
    private static instance: NetworkManager

    public static getInstance(): NetworkManager {
        if (NetworkManager.instance == null) {
            NetworkManager.instance = new NetworkManager()
        }
        return NetworkManager.instance
    }

    /***************************************** 初始化 *****************************************/

    _baseUrl: String = 'http://localhost:8080/'

    private constructor() {

    }



    /***************************************** 服务器地址定义 *****************************************/

    request<T>(req : NetworkRequest): Promise<NetworkResponse<T>> {
        // 组合 header
        let requestHeader = new AxiosHeaders()
        requestHeader.set('CS-OB-DEVICE', DeviceName)
        requestHeader.set('CS-OB-PLATFORM', DevicePlatform)
        requestHeader.set('CS-OB-MACHINE-CODE', DeviceMachineCode)
        requestHeader.set('CS-OB-VERSION-CODE', 10000)
        requestHeader.set('CS-OB-TOKEN', KV.get(KVUserToken))
        req.header?.forEach((value, key) => {
            requestHeader.set(key.toString(), value)
        })
        // 网络请求
        return axios({
            method: req.method,
            baseURL: KV.get(KVServerAddress),
            url: req.path,
            params: req.params,
            headers: requestHeader,
            data: req.data,
            timeout: 15000,
        }).then((response) => {
            if (response.status !== 200) {
                throw new Error(`网络请求失败：${response.statusText} (${response.status})`)
            }
            const responseData = response.data;
            return responseData as NetworkResponse<T>
        });
    }


}


enum NetworkRequestMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Patch = 'PATCH',
    Head = 'HEAD',
    Options = 'OPTIONS'
}


interface NetworkRequest {
    method: NetworkRequestMethod,
    path: string,
    params?: any|undefined,
    header?: Map<any, any>|undefined,
    data?: any|undefined,
}


interface NetworkResponse<T> {
    code: number
    data?: T
    message: string
}


const Network = NetworkManager.getInstance()

export default Network

export {
    NetworkRequestMethod,
}
