import React, {useEffect, useState} from "react"

import {Button, Checkbox, Input, Modal, Space, Toast} from "antd-mobile";

import styles from './index.module.css'
import {NavLink, useNavigate} from "react-router-dom";
import {RouteIndex, RouteUpdateServerAddress} from "../../route/route";
import KV from "../../module/kv/kv";
import {
    KVUserToken,
    KVUserTokenAuthTime,
    KVUserTokenExpireTime,
    KVUserUsername,
    KVUserUserID
} from "../../module/kv/kv_user_def";
import {NetworkLogin} from "../../module/network/network_login";
import KVStorage from "../../module/kv/kv_storage";
import Router from "../../route/router";

function Login(): React.JSX.Element {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        // 读取缓存的用户名称
        const kvUsername = KV.get(KVUserUsername)
        setUsername(kvUsername)
    }, [])


    const onTapForgetPassword = () => {
        Modal.alert({
            content: '请联系管理员重置密码',
        });
    }


    const onTapLogin = () => {
        if (isLoggingIn) {
            return
        }

        setIsLoggingIn(true)

        if (username.length === 0) {
            setIsLoggingIn(false)
            Toast.show('请输入账号')
            return
        }

        if (password.length === 0) {
            setIsLoggingIn(false)
            Toast.show('请输入密码')
            return
        }


        NetworkLogin(username, password).then(response => {
            setIsLoggingIn(false)
            // 给予提示
            Toast.show('登录成功')
            // 返回主页
            Router.replaceIndex(navigate)
            // 保存用户信息
            KV.set(KVUserUsername, username)
        }).catch((error) => {
            setIsLoggingIn(false)
        });

    }


    return (
        <div className={styles.container}>

            <span className={styles.title}>橘子记账</span>

            <Input
                className={styles.inputLogin}
                placeholder='请输入账号'
                value={username}
                onChange={val => {
                    setUsername(val)
                }}
            />

            <Input
                className={styles.inputPassword}
                placeholder='请输入密码'
                value={password}
                onChange={(val) => {
                    setPassword(val)
                }}
                type={isShowPassword ? 'text' : 'password'}
            />

            <div className={styles.containerExtra}>

                <a
                    className={styles.containerExtraForgetPassword}
                    onClick={onTapForgetPassword}
                >
                    忘记密码?
                </a>

                <Checkbox
                    checked={isShowPassword}
                    className={styles.containerExtraShowPassword}
                    onChange={(val) => {
                        setIsShowPassword(val)
                    }}
                >
                    显示密码
                </Checkbox>
            </div>

            <Button
                block
                className={styles.btnLogin}
                color='primary'
                loading={isLoggingIn}
                loadingText='正在加载'
                onClick={onTapLogin}
            >
                {isLoggingIn ? '登录中' : '登录'}
            </Button>

            <NavLink to={RouteUpdateServerAddress}>
                <span className={styles.txtUpdateServerAddress}>设置服务器地址</span>
            </NavLink>
        </div>
    )
}


export default Login;
