import KVItem from "./kv_item";
import KVStorage from "./kv_storage";

class _KV {

    /***************************************** 单例定义 *****************************************/

    private static instance: _KV | null = null;

    private constructor() {

    }

    public static getInstance(): _KV {
        if (_KV.instance == null) {
            _KV.instance = new _KV();
        }
        return _KV.instance;
    }

    /***************************************** 设置方法 *****************************************/

    set<T>(item:KVItem<T>, value: T) {
        KVStorage.set(item, value)
    }

    get<T>(item:KVItem<T>): T {
        return KVStorage.get(item)
    }

    remove(item:KVItem<any>) {
        KVStorage.remove(item)
    }

}


const KV = _KV.getInstance();


export default KV;
