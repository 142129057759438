import KVItem from './kv_item'


const _SCOPE_USER = 'user'


/* 账号名 */
const KVUserUsername = new KVItem(_SCOPE_USER, 'username', '')
/* 账号鉴权信息 */
const KVUserToken = new KVItem(_SCOPE_USER, 'token', '')
/* 用户ID */
const KVUserUserID = new KVItem(_SCOPE_USER, 'user_id', 0)
/* 鉴权过期时间 */
const KVUserTokenExpireTime = new KVItem(_SCOPE_USER, 'expire_time', 0)
/* 鉴权时间 */
const KVUserTokenAuthTime = new KVItem(_SCOPE_USER, 'auth_time', 0)


export {
    KVUserUsername,
    KVUserUserID,
    KVUserToken,
    KVUserTokenExpireTime,
    KVUserTokenAuthTime,
}