import React, {useEffect, useState} from "react";
import {Button, Popup} from "antd-mobile";
import {UserCircleOutline} from "antd-mobile-icons";

import styles from './index.module.css'
import KV from "../../../../module/kv/kv";
import {KVUserUsername} from "../../../../module/kv/kv_user_def";


interface PopupUserInfoProps {
    isShow: boolean,
    onDismiss: () => void,
    onSummary: () => void,
    onManageTag: () => void,
    onClearCache: () => void,
    onLogout: () => void,
}


const PopupUserInfo = (props: PopupUserInfoProps) : React.JSX.Element => {
    const { isShow, onDismiss, onSummary, onManageTag, onClearCache, onLogout } = props;

    const [username, setUsername] = useState('')

    useEffect(() => {
        if (!isShow) return;
        setUsername(KV.get(KVUserUsername))
    }, [isShow]);

    return (
        <Popup
            visible={isShow}
            onMaskClick={onDismiss}
            position="left"
            bodyStyle={{
                width: '80%',
                height: '100%'
            }}
        >
            <div className={styles.container}>


                <div className={styles.userInfoContainer}>
                    <UserCircleOutline width={60} height={60} />
                    <span className={styles.userInfoName}>{username}</span>
                </div>

                <Button
                    className={styles.userInfoSection}
                    fill={"none"}
                    color="default"
                    block
                    size="small"
                    onClick={() => {
                        onDismiss()
                        onSummary()
                    }}
                >
                    账单统计
                </Button>

                <Button
                    className={styles.userInfoSection}
                    fill={"none"}
                    color="default"
                    block
                    size="small"
                    onClick={() => {
                        onDismiss()
                        onManageTag()
                    }}
                >
                    标签管理
                </Button>

                <Button
                    className={styles.userInfoSection}
                    fill={"none"}
                    color="default"
                    block
                    size="small"
                    onClick={() => {
                        onDismiss()
                        onClearCache()
                    }}
                >
                    清除缓存
                </Button>

                <Button
                    className={styles.userInfoLogout}
                    color='primary'
                    fill='outline'
                    block
                    onClick={() => {
                        onDismiss()
                        onLogout()
                    }}
                >
                    退出登录
                </Button>




            </div>
        </Popup>
    );
}


export default PopupUserInfo;
