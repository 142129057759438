import BaseEvent from "./base_event";
import {EventBillRecordEdit} from "./events_bill_record";

class _EventCenter {

    private eventMap: Map<string, Array<(event:any) => void>>;

    constructor() {
        this.eventMap = new Map<string, Array<(event:any) => void>>();
    }

    public addEventListener<T extends BaseEvent>(eventKey: string, callback: ((event:T) => void)) {
        if (!this.eventMap.has(eventKey)) {
            this.eventMap.set(eventKey, []);
        }
        const callbacks = this.eventMap.get(eventKey);
        if (!callbacks) {
            return;
        }
        callbacks.push(callback);
    }

    public removeEventListener<T extends BaseEvent>(eventKey: string, callback: ((event:T) => void)) {
        if (!this.eventMap.has(eventKey)) {
            return;
        }
        const callbacks = this.eventMap.get(eventKey);
        if (!callbacks) {
            return;
        }
        const index = callbacks.indexOf(callback);
        if (index !== -1) {
            callbacks.splice(index, 1);
        }
    }

    public notify<T extends BaseEvent>(event: T) {
        const eventKey = event.getTag()
        const callbacks = this.eventMap.get(eventKey);
        if (!callbacks) {
            return;
        }
        for (let i = 0; i < callbacks.length; i++) {
            try {
                callbacks[i](event);
            } catch (e) {
                console.error(e);
            }
        }
    }

    public clear() {
        this.eventMap.clear();
    }


}


const EventCenter = new _EventCenter();

export default EventCenter;
