import BaseEvent from "./base_event"

class EventBillTagAdd extends BaseEvent {
    static readonly tag: string = "EventBillTagAdd";
}

class EventBillTagEdit extends BaseEvent {
    static readonly tag: string = "EventBillTagEdit";
}

export {
    EventBillTagAdd,
    EventBillTagEdit
}
