import React, {useState} from "react";
import {PickerView, Popup} from "antd-mobile";

import styles from './index.module.css';

interface Prop {
    isShow: boolean;
    onClosed?: () => void;
    onConfirm?: (year:number, month: number, second?:number) => void;
    isShowSecond?: boolean;
    currentTime?: Date;
}

const TimePicker = (prop: Prop): React.JSX.Element => {
    const {isShow, onClosed, onConfirm, isShowSecond, currentTime} = prop;

    const hourArray = [];
    for (let i = 0; i < 24; i++) {
        hourArray.push({
            label: i.toString(),
            value: i
        })
    }

    const minuteArray = [];
    for (let i = 0; i < 60; i++) {
        minuteArray.push({
            label: i.toString(),
            value: i
        })
    }

    const secondArray = [];
    for (let i = 0; i < 60; i++) {
        secondArray.push({
            label: i.toString(),
            value: i
        })
    }

    const pickerData = []
    pickerData.push(hourArray)
    pickerData.push(minuteArray)
    if (isShowSecond) {
        pickerData.push(secondArray)
    }

    let resultTimestamp = Date.now();
    if (currentTime) {
        resultTimestamp = currentTime.getTime();
    }


    let tmpPickerValue: any[] = [];
    const tmpDate = new Date(resultTimestamp);
    tmpPickerValue.push(tmpDate.getHours())
    tmpPickerValue.push(tmpDate.getMinutes())
    if (isShowSecond) {
        tmpPickerValue.push(tmpDate.getSeconds())
    }
    const [pickerValue, setPickerValue] = useState(tmpPickerValue)


    return (
        <Popup
            visible={isShow}
            onClose={onClosed}
            onMaskClick={onClosed}
        >

            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <span className={styles.btnCancel} onClick={onClosed}>取消</span>
                    <span className={styles.btnConfirm} onClick={() => {
                        if (onConfirm) {
                            const tmpDate = new Date(resultTimestamp);
                            let tmpHour = tmpDate.getHours();
                            if (pickerValue.length >= 1) {
                                tmpHour = parseInt(pickerValue[0].toString());
                            }
                            let tmpMinute = tmpDate.getMinutes();
                            if (pickerValue.length >= 2) {
                                tmpMinute = parseInt(pickerValue[1].toString());
                            }
                            let tmpSecond = tmpDate.getSeconds()
                            if (isShowSecond && pickerValue.length >= 3) {
                                tmpSecond = parseInt(pickerValue[2].toString());
                            }
                            onConfirm(tmpHour, tmpMinute, isShowSecond ? tmpSecond : undefined)
                        }
                        if (onClosed) {
                            onClosed()
                        }
                    }}>确定</span>
                </div>
            </div>

            <PickerView
                mouseWheel
                className={styles.picker}
                columns={pickerData}
                value={pickerValue}
                onChange={value => {
                    setPickerValue(value)
                }}
                style={{ '--height': '500px', '--item-height': '2.8rem' }}
            />


        </Popup>
    )
}


export default TimePicker;
