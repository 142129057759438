import React from "react";

import styles from './index.module.css'

interface Prop {
    icon?: React.ReactNode,
    name: string,
    color: string,
    onClick?: () => void,
}

// 弃用， 使用 @{Tag} 代替
const BillTagItem = (props: Prop) : React.JSX.Element => {
    const { icon , name, color, onClick } = props;

    return (
        <div className={styles.container} style={{ backgroundColor:color }} onClick={onClick}>
            { icon ? icon : null }
            <span className={styles.name}>{ name }</span>
        </div>
    )
}

export default BillTagItem
