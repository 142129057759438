
class KVItem<T> {
    scope: string;
    key: string;
    defaultValue: T;

    constructor(scope: string, key: string, defaultValue: T) {
        this.scope = scope;
        this.key = key;
        this.defaultValue = defaultValue;
    }

}

export default KVItem;
