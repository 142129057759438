import React from "react";

import styles from './index.module.css'
import {AddOutline} from "antd-mobile-icons";


interface Prop {
    onClick: () => void
}

const BillTagGroupAdd  = (prop: Prop) : React.JSX.Element => {
    return (
        <div className={styles.container} onClick={prop.onClick}>
            <AddOutline className={styles.icon} fontSize={24} />
            <span className={styles.title}>添加标签分组</span>
        </div>
    )
}


export default BillTagGroupAdd
