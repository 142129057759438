import Network, {NetworkRequestMethod} from "./network";

/***********************************************************************************************************************
 * 通用模型定义
 */

interface NetworkBillTag {
    id: number,
    name: string,
    sort: number,
    color: string,
    parent_id: number,
}

/***********************************************************************************************************************
 * 新建账单标签
 */

interface NetworkBillTagCreateProp {
    name: string,
    color?: string,
    parent_id?: number,
}

const NetworkBillTagCreate = (prop: NetworkBillTagCreateProp): Promise<NetworkBillTag> => {
    let requestName = prop.name
    let requestColor = prop.color
    if (requestColor !== undefined && requestColor.startsWith('#')) {
        requestColor = requestColor.substring(1)
    }
    let requestParentID = prop.parent_id

    const requestData: {[key:string]: any} = {}
    requestData['name'] = requestName
    if (requestColor !== undefined) requestData['color'] = requestColor
    requestData['parent_id'] = requestParentID

    return Network.request<NetworkBillTag>({
        method: NetworkRequestMethod.Put,
        path: '/api/billtag',
        data: requestData,
    }).then(response => {
        if (response.code !== 200) {
            throw new Error(response.message)
        }
        return response.data as NetworkBillTag
    })
}

/***********************************************************************************************************************
 * 编辑账单标签
 */

interface NetworkBillTagEditProp {
    tagID: number,
    name: string,
    color?: string,
}

const NetworkBillTagEdit = (prop: NetworkBillTagEditProp): Promise<NetworkBillTag> => {
    let requestName = prop.name
    let requestColor = prop.color
    let requestTagID = prop.tagID
    if (requestColor !== undefined && requestColor.startsWith('#')) {
        requestColor = requestColor.substring(1)
    }

    const requestData: {[key:string]: any} = {}
    requestData['name'] = requestName
    if (requestColor !== undefined) requestData['color'] = requestColor

    return Network.request<NetworkBillTag>({
        method: NetworkRequestMethod.Post,
        path: `/api/billtag/${requestTagID}`,
        data: requestData,
    }).then(response => {
        if (response.code !== 200) {
            throw new Error(response.message)
        }
        return response.data as NetworkBillTag
    })
}


/***********************************************************************************************************************
 * 获取账单标签列表
 */

const NetworkBillTagList = () : Promise<NetworkBillTag[]> => {
    return Network.request({
        method: NetworkRequestMethod.Get,
        path: '/api/billtag/list',
    }).then(response => {
        if (response.code !== 200) {
            throw new Error(response.message)
        }
        return response.data as NetworkBillTag[]
    })
}

/***********************************************************************************************************************
 * 删除账单
 */

const NetworkBillTagDelete = (tagID: number) : Promise<void> => {
    return Network.request({
        method: NetworkRequestMethod.Delete,
        path: `/api/billtag/${tagID}`,
    }).then(response => {
        if (response.code !== 200) {
            throw new Error(response.message)
        }
        return
    })
}


export type {
    NetworkBillTag,
    NetworkBillTagCreateProp,
    NetworkBillTagEditProp,
}

export {
    NetworkBillTagCreate,
    NetworkBillTagList,
    NetworkBillTagEdit,
    NetworkBillTagDelete,
}
