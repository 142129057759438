import React, {useEffect, useState} from "react";

import styles from './index.module.css'
import AppBar, {AppBarAction} from "../../compoment/AppBar";
import {DotLoading, Space, Toast} from "antd-mobile";
import BillTagGroupAdd from "./component/BillTagGroupAdd";
import Router from "../../route/router";
import {useNavigate} from "react-router-dom";
import BillTagCache, {BillTag} from "../../module/cache/billtag_cache";
import BillTagGroup from "./component/BillTagGroup";

const BillTagManage = (): React.JSX.Element => {
    const navigate = useNavigate();

    const [pageIsRefreshing, setPageIsRefreshing] = useState(false)
    const [pageTagList, setPageTagList] = useState<BillTag[]>([])

    /* 事件 - 刷新标签 */
    const actionLoadTags = (forceReload: boolean) => {
        setPageIsRefreshing(true)
        BillTagCache.loadTags(forceReload).then((tagList) => {
            console.log('tagList', tagList)
            setPageTagList(tagList)
            setPageIsRefreshing(false)
        }).catch(err => {
            Toast.show(`加载标签失败, ${err}`)
            setPageIsRefreshing(false)
        })
    }

    /* 页面初始化刷新 */
    useEffect(() => actionLoadTags(false), [])

    return (
        <div className={styles.container}>
            <AppBar
                title={'标签管理'}
                actionNode={(
                    <Space>
                        <AppBarAction
                            text={pageIsRefreshing ? undefined : '刷新'}
                            icon={pageIsRefreshing ? <DotLoading color="primary"/> : undefined}
                            onClick={() => actionLoadTags(true)}
                        />
                        {/*<AppBarAction*/}
                        {/*    text={'排序'}*/}
                        {/*    onClick={() => {*/}
                        {/*        Toast.show('排序')*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Space>
                )}
            />
            <div className={styles.containerTagGroup}>
                {
                    pageTagList.map((tagGroup, index) => {
                        return (
                            <BillTagGroup
                                key={`bill_tag_manage_list_${index}_${tagGroup.id}`}
                                tag={tagGroup}
                                onClick={() => {
                                    Router.pushBillTagEdit(navigate, tagGroup.id)
                                    console.log('onClick', tagGroup)
                                }}
                                onTagEditClick={(tagID) => {
                                    Router.pushBillTagEdit(navigate, tagID)
                                    console.log('onTagEditClick', tagID)
                                }}
                                onTagAddClick={() => {
                                    Router.pushBillTagAdd(navigate, tagGroup.id)
                                    console.log('onTagAddClick', tagGroup.id)
                                }}
                            />
                        )
                    })
                }
                <BillTagGroupAdd
                    onClick={() => {
                        Router.pushBillTagAdd(navigate)
                    }}
                />
            </div>
        </div>
    )
}


export default BillTagManage
