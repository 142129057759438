
abstract class BaseEvent {
    static readonly tag: string;

    getTag(): string {
        return (this.constructor as typeof BaseEvent).tag;
    }

}

export default BaseEvent;
