import React from "react";
import styles from './index.module.css';



interface Prop {
    className?: string|undefined,
    orientation?: 'row' | 'column',
    justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly',
    align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
    width?: string|number,
    height?: string|number,
    children?: React.ReactNode,
    overflowX?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
    overFlowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
    overFlow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
    onClick?: () => void,
    gap?: number|string|undefined,
    padding?: string|number|undefined,
    paddingHorizontal?: string|number|undefined,
    paddingVertical?: string|number|undefined,
    paddingTop?: string|number|undefined,
    paddingBottom?: string|number|undefined,
    paddingLeft?: string|number|undefined,
    paddingRight?: string|number|undefined,
    margin?: string|number|undefined,
    marginHorizontal?: string|number|undefined,
    marginVertical?: string|number|undefined,
    marginTop?: string|number|undefined,
    marginBottom?: string|number|undefined,
    marginLeft?: string|number|undefined,
    marginRight?: string|number|undefined,
    flex?: number|string|undefined,
    style?: React.CSSProperties,
}

const Flex = (prop: Prop): React.JSX.Element => {
    const propClassName = prop.className
    const propOrientation = prop.orientation ?? 'column'
    const propWidth = prop.width
    const propHeight = prop.height
    const propJustify = prop.justify ?? 'flex-start'
    const propAlign = prop.align ?? 'flex-start'
    const propWrap = prop.wrap ?? 'nowrap'
    const propChildren = prop.children
    const propOverflowX = prop.overflowX ?? prop.overFlow ?? 'visible'
    const propOverflowY = prop.overFlowY ?? prop.overFlow ?? 'visible'
    const propOnClick = prop.onClick
    const propGap = prop.gap
    const propStyle = prop.style
    const propPadding = prop.padding
    const propPaddingTop = prop.paddingTop
    const propPaddingBottom = prop.paddingBottom
    const propPaddingLeft = prop.paddingLeft
    const propPaddingRight = prop.paddingRight
    const propPaddingHorizontal = prop.paddingHorizontal
    const propPaddingVertical = prop.paddingVertical
    const propMargin = prop.margin
    const propMarginTop = prop.marginTop
    const propMarginBottom = prop.marginBottom
    const propMarginLeft = prop.marginLeft
    const propMarginRight = prop.marginRight
    const propMarginHorizontal = prop.marginHorizontal
    const propMarginVertical = prop.marginVertical
    const propFlex = prop.flex

    return (
        <div
            className={propClassName}
            onClick={propOnClick}
            style={{
                display: "flex",
                flexDirection: propOrientation,
                width: propWidth,
                height: propHeight,
                justifyContent: propJustify,
                alignItems: propAlign,
                flexWrap: propWrap,
                overflowX: propOverflowX,
                overflowY: propOverflowY,
                gap: propGap,
                marginTop: propMarginTop ?? propMarginVertical ?? propMargin,
                marginBottom: propMarginBottom ?? propMarginVertical ?? propMargin,
                marginLeft: propMarginLeft ?? propMarginHorizontal ?? propMargin,
                marginRight: propMarginRight ?? propMarginHorizontal ?? propMargin,
                paddingTop: propPaddingTop ?? propPaddingVertical ?? propPadding,
                paddingBottom: propPaddingBottom ?? propPaddingVertical ?? propPadding,
                paddingLeft: propPaddingLeft ?? propPaddingHorizontal ?? propPadding,
                paddingRight: propPaddingRight ?? propPaddingHorizontal ?? propPadding,
                flex: propFlex,
                ...propStyle
            }}
        >
            { propChildren }
        </div>
    )
}

export default Flex
