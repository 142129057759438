import React, {useEffect, useRef, useState} from "react";
import styles from './index.module.css'
import AppBar, {AppBarNavIcon} from "../../compoment/AppBar";
import Router from "../../route/router";
import {useNavigate} from "react-router-dom";
import useSummaryStore from "./state";
import {CalendarPicker, CalendarPickerRef, Dropdown, DropdownRef, List, Skeleton} from "antd-mobile";
import LoadState from "../../definition/load_state";
import {Durations} from "./definitions";
import dayjs from "dayjs";


function Summary(): React.JSX.Element {
    const navigator = useNavigate()
    const store = useSummaryStore()

    const [stateVisibleCalendarPicker, setStateVisibleCalendarPicker] = useState(false)

    const refDropDown = useRef<DropdownRef>(null)
    const refCalendarPicker = useRef<CalendarPickerRef>(null)

    const stateDateStartCalendarPicker = new Date()
    stateDateStartCalendarPicker.setFullYear(2022, 6, 1)

    const stateDateEndCalendarPicker = new Date()

    useEffect(() => store.onInit(), []);

    const functionSelectDuration = (duration: Durations) => {
        refDropDown.current?.close()
        if (duration === Durations.Custom) {
            setStateVisibleCalendarPicker(true)
            setTimeout(() => {
                const el = document.getElementById(dayjs(store.stateStartTime).format('YYYY-MM-DD'))
                setTimeout(() => {
                    el?.scrollIntoView({behavior: 'smooth', block: 'center'})
                })
                refCalendarPicker.current?.jumpToToday()
            }, 100)
        } else {
            store.actionSelectDuration(duration)
            store.actionFetchData()
        }
    }

    const functionGetDuration = (): string => {
        let result = ''
        switch (store.stateDurations) {
            case Durations.Today: {
                result = '今天'
                break
            }
            case Durations.Yesterday: {
                result = '昨天'
                break
            }
            case Durations.TheDayBeforeYesterday: {
                result = '前天'
                break
            }
            case Durations.ThisWeek: {
                result = '本周'
                break
            }
            case Durations.LastWeek: {
                result = '上周'
                break
            }
            case Durations.ThisMonth: {
                result = '本月'
                break
            }
            case Durations.LastMonth: {
                result = '上月'
                break
            }
            case Durations.Custom: {
                result = '自定义'
                break
            }
        }
        return result
    }

    /* 获取开始时间到结束时间范围的文本 */
    const functionGetDateRangeText = (): string => {
        let startDateText = dayjs(store.stateStartTime).format('YYYY年MM月DD日')
        let endDateText = dayjs(store.stateEndTime).format('YYYY年MM月DD日')
        if (startDateText === endDateText) {
            return startDateText
        } else {
            return startDateText + ' ~ ' + endDateText
        }
    }

    return (
        <div className={styles.container}>

            <AppBar
                title={'账单统计'}
                navIcon={AppBarNavIcon.Back}
                onTapNav={() => Router.goBack(navigator)}
                divider={'none'}
            />

            <div className={styles.containerHeader}>
                <Dropdown
                    ref={refDropDown}
                    style={{width: '100%'}}
                    closeOnClickAway
                    closeOnMaskClick
                >
                    <Dropdown.Item key='dropdownTime' title={functionGetDuration()}>
                        <List>
                            <List.Item onClick={() => functionSelectDuration(Durations.Today)}>今天</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.Yesterday)}>昨天</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.TheDayBeforeYesterday)}>前天</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.ThisWeek)}>本周</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.LastWeek)}>上周</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.ThisMonth)}>本月</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.LastMonth)}>上月</List.Item>
                            <List.Item onClick={() => functionSelectDuration(Durations.Custom)}>自定义</List.Item>
                        </List>
                    </Dropdown.Item>
                </Dropdown>
            </div>

            <div className={styles.containerBody}>


                {
                    store.statePageState === LoadState.Loading ? (
                        <div className={styles.containerSkeleton}>
                            <Skeleton.Title animated/>
                            <Skeleton.Paragraph animated lineCount={3}/>
                            <Skeleton.Paragraph animated lineCount={2}/>
                            <Skeleton.Paragraph animated lineCount={3}/>
                        </div>
                    ) :  store.statePageState === LoadState.Success ? (
                        <div className={styles.containerGraph}>

                            <div className={styles.section}>
                                <h2 className={styles.title}>{ functionGetDateRangeText() }</h2>
                            </div>

                            {
                                store.stateSummaryTotal ? (
                                    <div className={styles.section}>
                                        <h2 className={styles.title}>总计</h2>
                                        <span className={styles.paragraph}>总消费：{store.stateSummaryTotal.money}</span>
                                        <span className={styles.paragraph}>收入：{store.stateSummaryTotal.money_income}</span>
                                        <span className={styles.paragraph}>支出：{store.stateSummaryTotal.money_outcome}</span>
                                        <span className={styles.paragraph}>笔数：{store.stateSummaryTotal.count}</span>
                                    </div>
                                ) : null
                            }

                            {
                                store.stateSummaryDay ? (
                                    <div className={styles.section}>
                                        <h2 className={styles.title}>日均统计</h2>

                                        {
                                            store.stateSummaryDay.data.length === 0 ? (
                                                <span className={styles.paragraph}>暂无数据</span>
                                            ) : null
                                        }

                                        {
                                            store.stateSummaryDay.data.map((item) => {
                                                return (
                                                    <div key={item.time} className={styles.graphItem}>
                                                        <span className={styles.paragraph}>日期：{dayjs(item.time).format('MM月DD日')}</span>
                                                        <span className={styles.paragraph}>总消费：{item.money}</span>
                                                        <span className={styles.paragraph}>笔数：{item.count}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ) : null
                            }

                        </div>
                    ) : null
                }

            </div>

            <CalendarPicker
                defaultValue={[new Date(store.stateStartTime), new Date(store.stateEndTime)]}
                ref={refCalendarPicker}
                renderDate={(date) => { return (<div id={dayjs(date).format('YYYY-MM-DD')} key={'calendar_picker_'+date.toDateString()}>{date.getDate()}</div>) }}
                min={stateDateStartCalendarPicker}
                max={stateDateEndCalendarPicker}
                selectionMode='range'
                visible={stateVisibleCalendarPicker}
                onClose={() => setStateVisibleCalendarPicker(false)}
                onMaskClick={() => setStateVisibleCalendarPicker(false)}
                onConfirm={(dates) => {
                    setStateVisibleCalendarPicker(false)
                    if (dates && dates.length >= 2) {
                        let dateStart = new Date(dates[0].getTime())
                        dateStart.setHours(0, 0, 0, 0)
                        let dateEnd = new Date(dates[1].getTime())
                        dateEnd.setHours(23, 59, 59, 999)

                        store.actionSelectCustomDuration(dateStart.getTime(), dateEnd.getTime())
                        store.actionFetchData()
                    }
                }}
            />

        </div>
    )
}


export default Summary;
