import {DotLoading, PickerView, Popup, Result} from "antd-mobile";

import styles from './index.module.css';
import {NetworkBillBook} from "../../module/network/network_billbook";
import {useEffect, useState} from "react";
import LoadState from "../../definition/load_state";
import BillBookCache from "../../module/cache/billbook_cache";

interface Prop {
    isShow: boolean;
    onClose?: () => void;
    onConfirm?: (billBookID: NetworkBillBook|undefined) => void;
    selectedBillBookID?: number;
}

const BillBookPicker = (props: Prop): React.JSX.Element => {
    const {isShow, onClose, onConfirm, selectedBillBookID} = props;
    const [loadState, setLoadState] = useState(LoadState.Loading)
    const [pickerData, setPickerData] = useState(new Array<Array<any>>())
    const [currentSelection, setCurrentSelection] = useState<number>(selectedBillBookID ?? 0)
    const [isLoadingResult, setIsLoadingResult] = useState(false)

    useEffect(() => {
        if (isShow) {
            setLoadState(LoadState.Loading)

            BillBookCache.getBillBookList().then((billBookList) => {
                const tmpBillBookData = []
                for (let tmpBillBook of billBookList) {
                    tmpBillBookData.push({
                        label: tmpBillBook.name,
                        value: tmpBillBook.id,
                    })
                }
                setPickerData([tmpBillBookData])
                setLoadState(LoadState.Success)
            }).catch(() => {
                setLoadState(LoadState.Fail)
            })
        }
    }, [isShow]);


    return (
        <Popup
            visible={isShow}
            onClose={onClose}
            onMaskClick={onClose}
        >
            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <span className={styles.headerCancel}>取消</span>

                    {
                        isLoadingResult ? (
                            <DotLoading className={styles.headerConfirm} color={"primary"} />
                        ) : (
                            <span className={styles.headerConfirm} onClick={() => {
                                setIsLoadingResult(true)
                                BillBookCache.getBillBook(currentSelection).then((billBook) => {
                                    setIsLoadingResult(false)
                                    if (onConfirm) {
                                        onConfirm(billBook)
                                    }
                                    // 关闭弹窗
                                    if (onClose) {
                                        onClose();
                                    }
                                }).catch((error) => {
                                    setIsLoadingResult(false)
                                    console.log(error)
                                })
                            }}>确定</span>
                        )
                    }
                </div>
            </div>

            {
                loadState === LoadState.Loading ? (
                    <Result
                        className={styles.picker}
                        status={'waiting'}
                        title={'加载中'}
                    />
                ) : null
            }

            {
                loadState === LoadState.Fail ? (
                    <Result
                        className={styles.picker}
                        status={'error'}
                        title={'加载失败'}
                    />
                ) : null
            }

            {
                loadState === LoadState.Success ? (
                    <PickerView
                        mouseWheel
                        columns={pickerData}
                        value={[currentSelection]}
                        className={styles.picker}
                        onChange={result => {
                            console.log(result)
                            if (result.length < 1) {
                                return
                            }
                            setCurrentSelection(result[0] as number)
                        }}
                        style={{ '--height': '500px', '--item-height': '2.8rem' }}
                    />
                ) : null
            }
        </Popup>
    )
}

export default BillBookPicker;
