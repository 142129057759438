
import Network, {NetworkRequestMethod} from "./network";

/***********************************************************************************************************************
 * 请求获取统计数据
 */

interface NetworkSummaryRequest {
    startTime?: number | undefined, // 开始时间，秒级时间戳
    endTime?: number | undefined, // 结束时间，秒级时间戳
    books?: number[] | undefined, // 账单ID集合
    tags?: number[] | undefined, // 标签ID集合
    type?: number[] | undefined, // 类型ID集合
}

interface NetworkSummaryResponse {
    total: { // 总计
        money: number, // 总金额
        money_income: number, // 总收入
        money_outcome: number, // 总支出
        count: number, // 总笔数
    },
    days: { // 按天统计, key为YYYYMMDD, value为统计数据
        date: string, // 日期
        money: number, // 当天总金额
        money_income: number, // 当天总收入
        money_outcome: number, // 当天总支出
        count: number, // 当天总笔数
        tags: Map<String, { // 当天标签统计, key为标签id, value为统计数据
            money: number, // 当天标签总金额
            money_income: number, // 当天标签总收入
            money_outcome: number, // 当天标签总支出
            count: number, // 当天标签总笔数
        }>
    }[],
}

const NetworkSummary = (params: NetworkSummaryRequest) : Promise<NetworkSummaryResponse> => {
    return Network.request({
        method: NetworkRequestMethod.Get,
        path: '/api/summary',
        params: {
            start_time: params.startTime,
            end_time: params.endTime,
            books: params.books,
            tags: params.tags,
            type: params.type,
        },
    }).then((response) => {
        if (response.code !== 200) {
            throw new Error(response.message)
        }
        return response.data as NetworkSummaryResponse
    })
}

/**************** 导出内容 ****************/

export type {
    NetworkSummaryRequest,
    NetworkSummaryResponse,
}

export {
    NetworkSummary
}
