import React from "react";
import { AddOutline } from "antd-mobile-icons";

import styles from './index.module.css'

interface Prop {
    onTap: () => void,
}

const BillBookAdd = (prop: Prop) : React.JSX.Element => {
    const { onTap } = prop
    return (
        <div className={styles.container} onClick={onTap}>
            <AddOutline width={24} height={24} color="d9d9d9" />
            <span className={styles.text}>添加账本</span>
        </div>
    )
}


export default BillBookAdd;
