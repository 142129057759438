import React from "react";

import styles from './index.module.css'
import Theme from "../../../../definition/theme";
import {Divider, Popover, Space, Tag} from "antd-mobile";
import BillTagCache, {BillTag} from "../../../../module/cache/billtag_cache";
import BillTagItem from "../BillTagItem";
import {AddOutline,RightOutline} from "antd-mobile-icons";

interface Prop {
    tag: BillTag,
    onClick?: () => void,
    onTagEditClick?: (tagID: number) => void,
    onTagAddClick?: () => void,
}

const BillTagGroup = (prop: Prop) : React.JSX.Element => {
    const {
        tag,
        onClick,
        onTagEditClick,
        onTagAddClick,
    } = prop;

    const DEF_POPOVER_ACTION_EDIT = 'edit'

    return (
        <div className={styles.container}>
            <div className={styles.tagGroupTitleContainer} onClick={onClick}>
                <div className={styles.colorTagGroupPreview} style={{ backgroundColor: BillTagCache.getTagColor(tag.id) }}/>
                <span className={styles.title}>{tag.name}</span>
                <RightOutline />
            </div>
            <div className={styles.divider} />
            <Space className={styles.tagGroupContainer} wrap direction="horizontal">
                {
                    tag.children?.map((item, index) => {
                        return (
                            <Popover.Menu
                                key={`bill_tag_group_item_${index}_${item.id}`}
                                actions={[
                                    { text: '编辑', key: DEF_POPOVER_ACTION_EDIT },
                                ]}
                                trigger="click"
                                onAction={(action) => {
                                    switch (action.key) {
                                        case DEF_POPOVER_ACTION_EDIT: {
                                            if (onTagEditClick) {
                                                onTagEditClick(item.id)
                                            }
                                            break;
                                        }
                                    }
                                }}
                            >
                                <BillTagItem
                                    name={item.name}
                                    color={BillTagCache.getTagColor(item.id)}
                                />
                            </Popover.Menu>
                        )
                    })
                }
                <BillTagItem
                    icon={<AddOutline className={styles.tagGroupAddIcon} />}
                    name='添加标签'
                    color={BillTagCache.getTagColor(tag.id)}
                    onClick={onTagAddClick}
                />
            </Space>
        </div>
    )

}

export default BillTagGroup
