import Network, {NetworkRequestMethod} from "./network";
import {DeviceMachineCode, DeviceName, DevicePlatform} from "../device_info/device_info";
import {SimpleError} from "../../definition/error";
import KV from "../kv/kv";
import {KVUserToken, KVUserTokenAuthTime, KVUserTokenExpireTime, KVUserUserID, KVUserUsername} from "../kv/kv_user_def";

/********************************************************************************************************************** 登录接口 */

interface NetworkLoginResponse {
    auth_time: number,
    device: string,
    expire_time: number,
    platform: string,
    token: string,
    user_id: number,
}

const NetworkLogin = (username: string, password: string): Promise<NetworkLoginResponse> => {
    return Network.request<NetworkLoginResponse>({
        method: NetworkRequestMethod.Post,
        path: '/api/login',
        data: {
            account: username,
            password: password,
            device: DeviceName,
            platform: DevicePlatform,
            machine_code: DeviceMachineCode,
        }
    }).then((response) => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkLoginResponse
    }).then((response) => {
        // 保存关键信息
        KV.set(KVUserUsername, username)
        KV.set(KVUserToken, response.token)
        KV.set(KVUserTokenAuthTime, response.auth_time)
        KV.set(KVUserUserID, response.user_id)
        KV.set(KVUserTokenExpireTime, response.expire_time)
        return response;
    })
};


/********************************************************************************************************************** 退出登录接口 */


const NetworkLogout = () => {
    return Network.request({
        method: NetworkRequestMethod.Post,
        path: '/api/logout',
    }).catch((error) => {
        console.log('退出登录请求失败')
    }).then(() => {
        // 清除关键信息
        KV.set(KVUserToken, '')
        KV.set(KVUserTokenAuthTime, 0)
        KV.set(KVUserUserID, 0)
        KV.set(KVUserTokenExpireTime, 0)
        KV.set(KVUserUsername, '')
    });
}


/********************************************************************************************************************** 刷新登录信息接口 */


const NetworkLoginVerify = () : Promise<NetworkLoginResponse> => {
    return Network.request({
        method: NetworkRequestMethod.Post,
        path: '/api/verify',
    }).then((response) => {
        if (response.code !== 200) {
            throw new SimpleError(response.message)
        }
        return response.data as NetworkLoginResponse
    }).then((response) => {
        // 更新关键信息
        KV.set(KVUserToken, response.token)
        KV.set(KVUserTokenAuthTime, response.auth_time)
        KV.set(KVUserUserID, response.user_id)
        KV.set(KVUserTokenExpireTime, response.expire_time)
        return response;
    });
}


export {
    NetworkLogin,
    NetworkLogout,
    NetworkLoginVerify,
}
