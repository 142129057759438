import styles from './index.module.css'
import AppBar from "../../compoment/AppBar";
import {Button, Input, NavBar, Toast} from "antd-mobile";
import {useEffect, useState} from "react";
import KV from "../../module/kv/kv";
import {KVServerAddress} from "../../module/kv/kv_server_def";
import {useNavigate} from "react-router-dom";
import Router from "../../route/router";

function UpdateServerAddress() {
    const [pageServerAddress, setPageServerAddress] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        const kvAddress = KV.get(KVServerAddress)
        setPageServerAddress(kvAddress)
    }, []);

    const onTapSetAddress = () => {
        KV.set(KVServerAddress, pageServerAddress)
        Toast.show('操作成功');
        Router.goBack(navigate)
    }

    return (
        <div className={styles.scaffold}>
            <AppBar
                title={"设置服务器地址"}
            />
            <div className={styles.content}>

                <Input
                    className={styles.input}
                    placeholder='请输入服务器地址'
                    value={pageServerAddress}
                    clearable
                    onChange={(val) => {
                        setPageServerAddress(val)
                    }}
                />

                <Button
                    className={styles.btn}
                    block
                    color='primary'
                    size='large'
                    onClick={onTapSetAddress}
                >
                    设置服务器地址
                </Button>
            </div>
        </div>
    );
}


export default UpdateServerAddress;
