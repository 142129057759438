import {create} from "zustand";
import LoadState from "../../definition/load_state";
import {NetworkBillBook} from "../../module/network/network_billbook";
import {NetworkBillRecord} from "../../module/network/network_billrecord";


interface State {
    isInit: boolean,
    selectedBillBookID: number,
    hasMoreBillRecord: boolean,
    billBookList: NetworkBillBook[],
    billRecordList: NetworkBillRecord[],
    isBillRecordUpdate: boolean, // 是否有账单记录更新，如果有则需要重新加载
}


interface Action {
    setIsInit: (isInit: boolean) => void,
    setSelectedBillBookID: (id: number) => void,
    setHasMoreBillRecord: (hasMore: boolean) => void,
    setBillBookList: (list: NetworkBillBook[]) => void,
    setBillRecordList: (list: NetworkBillRecord[]) => void,
    setIsBillRecordUpdate: (isUpdate: boolean) => void,
}


const useIndexPageSate = create<State & Action>((set, get) => {
    return {
        isInit: false,
        selectedBillBookID: 0,
        hasMoreBillRecord: true,
        billBookList: [],
        billRecordList: [],
        isBillRecordUpdate: false,

        setIsInit: (isInit: boolean) => {
            set({isInit: isInit})
        },
        setSelectedBillBookID: (id: number) => {
            set({selectedBillBookID: id})
        },
        setHasMoreBillRecord: (hasMore: boolean) => {
            set({hasMoreBillRecord: hasMore})
        },
        setBillBookList: (list: NetworkBillBook[]) => {
            set({billBookList: list})
        },
        setBillRecordList: (list: NetworkBillRecord[]) => {
            set({billRecordList: list})
        },
        setIsBillRecordUpdate: (isUpdate: boolean) => {
            set({isBillRecordUpdate: isUpdate})
        },
    }
})


export default useIndexPageSate
