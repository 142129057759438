
const DeviceName = 'Web Test'
const DeviceMachineCode = '0000000000000000'
const DevicePlatform = 'Web'


export  {
    DeviceName,
    DeviceMachineCode,
    DevicePlatform,
}
