import {create, StoreApi, UseBoundStore} from "zustand";
import LoadState from "../../definition/load_state";
import {NetworkSummary} from "../../module/network/network_summary";
import {
    Durations,
    SummaryChartDay,
    SummaryChartDayItem,
    SummaryChartTotal
} from "./definitions";
import {Toast} from "antd-mobile";
import dayjs from "dayjs";

interface StateAndAction {
    statePageState: LoadState,
    stateStartTime: number,
    stateEndTime: number,
    stateDurations: Durations,
    stateSummaryTotal?: SummaryChartTotal,
    stateSummaryDay?: SummaryChartDay,

    onInit: () => void,
    actionFetchData: () => void,
    actionSelectDuration: (duration: Durations) => void,
    actionSelectCustomDuration: (startTime: number, endTime: number) => void,
}


const useSummaryStore : UseBoundStore<StoreApi<StateAndAction>> = create((set, get) => ({
    statePageState: LoadState.Loading,
    stateStartTime: 0,
    stateEndTime: 0,
    stateDurations: Durations.Today,
    stateSummaryTotal: undefined,
    stateSummaryDay: undefined,
    stateSummaryTag: undefined,

    /* 进行初始化 */
    onInit: () => {
        get().actionSelectDuration(Durations.Today)
        get().actionFetchData()
    },

    /* 进行网络请求 */
    actionFetchData: () => {
        Promise.resolve().then(() => {
            // 界面设置加载中
            set({statePageState: LoadState.Loading})
        }).then(() => {
            // 网络请求
            // 获取秒级别开始时间
            let storeStartTime = get().stateStartTime
            if (storeStartTime > 1e12) {
                storeStartTime = Math.floor(storeStartTime / 1000)
            }
            // 获取毫秒级别开始时间
            let storeEndTime = get().stateEndTime
            if (storeEndTime > 1e12) {
                storeEndTime = Math.floor(storeEndTime / 1000)
            }
            return NetworkSummary({
                startTime: storeStartTime,
                endTime: storeEndTime,
            })
        }).then((response) => {
            // 数据组装
            // 组装总数
            let summaryTotal: SummaryChartTotal = {
                money: response.total.money,
                money_income: response.total.money_income,
                money_outcome: response.total.money_outcome,
                count: response.total.count,
            }
            // 组装日数据
            let summaryDayData: SummaryChartDayItem[] = []
            let responseDays = response.days
            for (let itemDate of responseDays) {
                let summaryDayItem: SummaryChartDayItem = {
                    time: dayjs(itemDate.date).valueOf(),
                    money: itemDate.money,
                    count: itemDate.count,
                }
                summaryDayData.push(summaryDayItem)
            }
            let summaryDay: SummaryChartDay = {
                data: summaryDayData,
            }
            // 更新数据
            set({
                statePageState: LoadState.Success,
                stateSummaryTotal: summaryTotal,
                stateSummaryDay: summaryDay,
            })
        }).catch((error) => {
            console.log('加载失败', error)
            set({statePageState: LoadState.Fail})
            Toast.show({
                icon: 'fail',
                content: error.message,
            })
        })
    },

    /* 选择时间 - 预设 */
    actionSelectDuration: (duration: Durations) => {

        let startTime = 0
        let endTime = 0

        switch (duration) {
            case Durations.Today: {
                startTime = new Date().setHours(0, 0, 0, 0)
                endTime = new Date().setHours(23, 59, 59, 999)
                break
            }
            case Durations.Yesterday: {
                startTime = new Date().setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000
                endTime = new Date().setHours(23, 59, 59, 999) - 24 * 60 * 60 * 1000
                break
            }
            case Durations.TheDayBeforeYesterday: {
                startTime = new Date().setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000 * 2
                endTime = new Date().setHours(23, 59, 59, 999) - 24 * 60 * 60 * 1000 * 2
                break
            }
            case Durations.ThisWeek: {
                const now = new Date();
                const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
                const startOfWeek = new Date(now);
                const endOfWeek = new Date(now);

                startOfWeek.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
                startOfWeek.setHours(0, 0, 0, 0);

                endOfWeek.setDate(now.getDate() + (7 - dayOfWeek) % 7);
                endOfWeek.setHours(23, 59, 59, 999);

                startTime = startOfWeek.getTime()
                endTime = endOfWeek.getTime()
                break
            }
            case Durations.LastWeek: {
                const now = new Date();
                const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
                const startOfWeek = new Date(now);
                const endOfWeek = new Date(now);

                startOfWeek.setDate(now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
                startOfWeek.setHours(0, 0, 0, 0);

                endOfWeek.setDate(now.getDate() + (7 - dayOfWeek) % 7);
                endOfWeek.setHours(23, 59, 59, 999);

                startTime = startOfWeek.getTime() - 7 * 24 * 60 * 60 * 1000
                endTime = endOfWeek.getTime() - 7 * 24 * 60 * 60 * 1000
                break
            }
            case Durations.ThisMonth: {
                const startDate = new Date()
                startDate.setHours(0, 0, 0, 0)
                startDate.setDate(1)

                const endDate = new Date()
                endDate.setHours(23, 59, 59, 999)
                endDate.setMonth(endDate.getMonth() + 1)
                endDate.setDate(0)

                startTime = startDate.getTime()
                endTime = endDate.getTime()
                break
            }
            case Durations.LastMonth: {
                const startDate = new Date()
                startDate.setHours(0, 0, 0, 0)
                startDate.setDate(1)
                startDate.setMonth(startDate.getMonth() - 1)

                const endDate = new Date()
                endDate.setHours(23, 59, 59, 999)
                endDate.setMonth(endDate.getMonth())
                endDate.setDate(0)

                startTime = startDate.getTime()
                endTime = endDate.getTime()
                break
            }
            default: {
                return
            }
        }

        set({
            stateStartTime: startTime,
            stateEndTime: endTime,
            stateDurations: duration,
        })
    },

    /* 选择时间 - 自定义 */
    actionSelectCustomDuration: (startTime: number, endTime: number) => {
        set({
            stateStartTime: startTime,
            stateEndTime: endTime,
            stateDurations: Durations.Custom,
        })
    },

}));


export default useSummaryStore;
