import React, {useEffect, useState} from "react";
import {Popup, Space, Toast} from "antd-mobile";
import AppBar, {AppBarAction, AppBarNavIcon} from "../AppBar";
import Flex from "../Flex";
import BillTagCache, {BillTag} from "../../module/cache/billtag_cache";
import Tag from "../Tag";
import Divider from "../Divider";
import styles from './index.module.css';

interface Prop {
    isVisible: boolean,
    onClose?: () => void,
    defaultTag?: Array<number>,
    onConfirm?: (tagID: Array<number>) => void,
}


const BillTagPicker = (prop: Prop): React.JSX.Element => {
    const propIsVisible = prop.isVisible
    const propOnClose = prop.onClose
    const propDefaultTag = prop.defaultTag
    const propOnConfirm = prop.onConfirm

    const [popupSelectionID, setPopupSelectionID] = useState(new Set<number>())
    const [popupTagList, setPopupTagList] = useState(new Array<BillTag>())

    useEffect(() => {
        if (propIsVisible) {
            setPopupSelectionID(new Set(propDefaultTag))
            BillTagCache.loadTags().then(tags => {
                setPopupTagList(tags)
            }).catch(err => {
                console.log('标签列表加载失败', err)
                Toast.show(`标签列表加载失败, ${err}`)
            })
        }
    }, [propIsVisible])

    const toggleSelectTag = (tagID: number) => {
        if (popupSelectionID.has(tagID)) {
            popupSelectionID.delete(tagID)
        } else {
            popupSelectionID.add(tagID)
        }
        setPopupSelectionID(new Set(popupSelectionID))
    }

    return (
        <Popup
            visible={propIsVisible}
            onMaskClick={propOnClose}
            onClose={propOnClose}
        >
            <Flex>
                <AppBar
                    title={'选择标签'}
                    navIcon={AppBarNavIcon.Close}
                    onTapNav={propOnClose}
                    actionNode={[
                        <AppBarAction
                            key={'action_confirm'}
                            text={'确定'}
                            onClick={() => {
                                if (propOnConfirm) propOnConfirm(Array.from(popupSelectionID))
                                if (propOnClose) propOnClose()
                            }}
                        />
                    ]}
                />

                <Divider/>

                <Flex height={'60vh'} overFlowY={'scroll'} padding={'16px 0'} width={'100%'}>
                    {
                        /***** 标签组 *****/
                        popupTagList.map(group => {
                            const groupChildren = group.children
                            const groupIsSelect = popupSelectionID.has(group.id)

                            return (
                                <div
                                    key={`tag_group_${group.id}`}
                                    className={styles.tagGroup}
                                >
                                    <Tag
                                        type={groupIsSelect ? 'fill' : 'outline'}
                                        size={'normal'}
                                        color={BillTagCache.getTagColor(group.id)}
                                        onClick={() => {
                                            toggleSelectTag(group.id)
                                        }}
                                    >
                                        { group.name }
                                    </Tag>
                                    {
                                        /***** 子标签 *****/
                                        groupChildren ? (
                                            <div
                                                className={styles.tagGroupChildren}
                                            >
                                                {
                                                    groupChildren.map(child => {
                                                        const childIsSelect = popupSelectionID.has(child.id)
                                                        return (
                                                            <Tag
                                                                key={`tag_${child.id}`}
                                                                type={childIsSelect ? 'fill' : 'outline'}
                                                                size={'normal'}
                                                                color={BillTagCache.getTagColor(child.id)}
                                                                onClick={() => {
                                                                    toggleSelectTag(child.id)
                                                                }}
                                                            >
                                                                { child.name }
                                                            </Tag>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ) : null
                                    }
                                    {/*  分割线  */}
                                    <Divider startDistance={'16px'} />
                                </div>
                            )
                        })
                    }
                </Flex>
            </Flex>
        </Popup>
    )
}


export default BillTagPicker
