import KVItem from "./kv_item";

abstract class _KVStorage {

    abstract set<T>(item:KVItem<T>, value: T): void;

    abstract get<T>(item:KVItem<T>): T;

}

const KVStorage = new class extends _KVStorage {


    set<T>(item:KVItem<T>, value: T) {
        const key = `${item.scope}.${item.key}`;

        if (typeof value === 'string') {
            localStorage.setItem(key, value)
        } else if (typeof value === 'number') {
            localStorage.setItem(key, value.toString())
        } else if (typeof value === 'boolean') {
            localStorage.setItem(key, value ? 'true' : 'false')
        } else if (typeof value === 'object') {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            throw new Error('Unsupported type')
        }
    }


    get<T>(item:KVItem<T>): T {
        const key = `${item.scope}.${item.key}`;
        const result = localStorage.getItem(key);

        if (result === null) {
            return item.defaultValue;
        }

        if (typeof item.defaultValue === 'number') {
            return Number(result) as any as T;
        } else if (typeof item.defaultValue === 'boolean') {
            if (result === 'true' || result === 'false') {
                return (result === 'true') as any as T;
            }
            throw new Error('Invalid boolean value')
        } else if (typeof item.defaultValue === 'string') {
            return result as any as T;
        } else if (typeof item.defaultValue === 'object') {
            return JSON.parse(result) as any as T;
        }

        throw new Error('Unsupported type')
    }

    remove(item:KVItem<any>) {
        const key = `${item.scope}.${item.key}`;
        localStorage.removeItem(key)
    }

}();


export default KVStorage;
