import KV from "../kv/kv";
import {KVCacheBillBook, KVCacheBillBookUpdateTime} from "../kv/kv_cache_def";
import {NetworkBillBook, NetworkBillBookList} from "../network/network_billbook";

class _BillBookCache {

    public static instance = new _BillBookCache();

    private constructor() {
        this.loadFromLocal()
    }

    /******************************************************************************************************************* 缓存内容 */
    cache = new Map<number, NetworkBillBook>(); // 缓存内容
    cacheRefreshing = false; // 是否正在更新缓存
    cacheUpdateTime = 0; // 缓存更新时间
    cacheHasData = false; // 缓存是否有数据（如果有数据则可以直接返回）
    cacheExpiredTime = 1000 * 60 * 60 * 24; // 缓存过期时间（24小时），超过这段时间后会静默更新


    /******************************************************************************************************************* 缓存加载 */

    private parseFromResponse(billBookList: NetworkBillBook[]) {
        const tmpCache = new Map<number, NetworkBillBook>()
        billBookList.forEach((billBook) => {
            tmpCache.set(billBook.id, billBook)
        })
        this.cache = tmpCache
        this.cacheHasData = true
    }

    private loadFromLocal() {
        // 读取缓存更新时间
        this.cacheUpdateTime = KV.get(KVCacheBillBookUpdateTime)
        // 读取并解析缓存
        try {
            const tmpCacheStr = KV.get(KVCacheBillBook)
            const tmpBillBookList = JSON.parse(tmpCacheStr) as NetworkBillBook[]
            this.parseFromResponse(tmpBillBookList)
        } catch (e) {
            // 缓存不存在或者解析失败
            console.log(`【BillBookCache】缓存不存在或者解析失败, error=${e}`)
        }
    }

    private loadFromServer(): Promise<void> {
        // TODO 这里可能有风险，当真的需要更新缓存时多次触发，则真的会发送多次请求
        /* 已在刷新的情况 - 等待刷新完成 */
        if (this.cacheRefreshing) {
            return new Promise(resolve => {
                /* 800ms后再检查 */
                setTimeout(() => {
                    resolve(0)
                }, 800)
            }).then(() => {
                /* 如果还是在刷新，则返回失败 */
                if (this.cacheRefreshing) {
                    return Promise.reject('缓存正在更新中，请稍后重试')
                }
                /* 如果没在刷新了并且有数据，则返回成功 */
                if (this.cacheHasData) {
                    return Promise.resolve()
                }
                /* 否则返回失败 */
                return Promise.reject()
            })
        }

        /* 没在刷新的情况 = 进行刷新 */
        this.cacheRefreshing = true
        return NetworkBillBookList().then(billBookList => {
            // 更新数据
            this.parseFromResponse(billBookList)
            // 保存到缓存中
            this.saveCache(billBookList)
            // 标志位更新
            this.cacheRefreshing = false
            // 返回成功
            return Promise.resolve()
        }).catch((e) => {
            this.cacheRefreshing = false
            // 返回失败
            return Promise.reject(e)
        })
    }

    private saveCache(response: any) {
        KV.set(KVCacheBillBook, JSON.stringify(response))
        KV.set(KVCacheBillBookUpdateTime, Date.now())
    }

    /******************************************************************************************************************* 缓存读取方法 */

    public getBillBookList(forceReload: boolean = false): Promise<NetworkBillBook[]> {
        return new Promise<NetworkBillBook[]|undefined>((resolve, reject) => {
            /* 读取本地缓存 */
            if (!forceReload && this.cacheHasData) {
                resolve(Array.from(this.cache.values()))
            } else {
                resolve(undefined)
            }
        }).then((localCache) => {
            /* 检查是否需要从网络加载 */
            return new Promise(resolve => {
                if (localCache === undefined) {
                    /* 缓存为空 - 则需要从网络中加载返回 */
                    return this.loadFromServer().then(() => {
                        resolve(Array.from(this.cache.values()))
                    })
                } else if (this.cacheUpdateTime - Date.now() > this.cacheExpiredTime) {
                    /* 缓存不为空，但是已经过期了，则需要静默刷新 */
                    return new Promise(resolve => {
                        resolve(localCache)
                        this.loadFromServer() // 静默拉取
                    })
                } else {
                    /* 缓存可用 */
                    resolve(localCache)
                }
            })
        })
    }


    public getBillBook(id: number): Promise<NetworkBillBook|undefined> {
        return new Promise(resolve => {
            resolve(0)
        }).then(() => {
            /* 是否需要等待网络请求返回 */
            if (!this.cacheHasData) {
                return this.loadFromServer()
            } else {
                return
            }
        }).then(() => {
            return this.getBillBookSync(id)
        })
    }


    public getBillBookSync(id: number): NetworkBillBook|undefined {
        // 检查是否需要更新缓存
        if (Date.now() - this.cacheUpdateTime > this.cacheExpiredTime) {
            this.loadFromServer()
        }
        // 返回缓存
        return this.cache.get(id) ?? undefined
    }


    public clearCache() {
        this.cache = new Map<number, NetworkBillBook>()
        this.cacheHasData = false
        this.cacheUpdateTime = 0
        this.cacheRefreshing = false
        KV.remove(KVCacheBillBook)
        KV.remove(KVCacheBillBookUpdateTime)
    }

}

const BillBookCache = _BillBookCache.instance;

export default BillBookCache;
