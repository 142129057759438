import React from "react";

interface Prop {
    orientation?: 'horizontal' | 'vertical',
    color?: string,
    startIndent?: string,
    endIndent?: string,
    indent?: string|number,
    startDistance?: string,
    endDistance?: string,
    margin?: string|number,
    width?: string|number,
    height?: string|number,
}

const Divider = (prop: Prop): React.JSX.Element => {
    const propIsHorizontal = prop.orientation ?? 'horizontal'
    const propColor = prop.color ?? '#e8e8e8'
    const propStartIndent = prop.startIndent ?? prop.indent ?? '0px'
    const propEndIndent = prop.endIndent ?? prop.indent ?? '0px'
    const propWidth = prop.width ?? (propIsHorizontal ? `calc(100% - ${propStartIndent} - ${propEndIndent})` : '1px')
    const propHeight = prop.height ?? (propIsHorizontal ? '1px' : '100%')
    const propStartDistance = prop.startDistance ?? prop.margin ?? '0px'
    const propEndDistance = prop.endDistance ?? prop.margin ?? '0px'

    return (
        <div
            style={{
                display: 'block',
                width: propWidth,
                height: propHeight,
                backgroundColor: propColor,
                marginLeft: propIsHorizontal ? propStartIndent : propStartDistance,
                marginRight: propIsHorizontal ? propEndIndent : propEndDistance,
                marginTop: propIsHorizontal ? propStartDistance : propStartIndent,
                marginBottom: propIsHorizontal ? propEndDistance : propEndIndent,
            }}
        />
    )
}

export default Divider
