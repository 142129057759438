
interface AppTheme {
    themeColor: string
}

const Theme : AppTheme = {
    themeColor: '#1890ff',
}

export default Theme
