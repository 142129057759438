import React from "react";

import styles from './index.module.css';
import Theme from "../../definition/theme";

interface Prop {
    type?: 'fill' | 'outline',
    size?: 'small' | 'normal' | 'large',
    padding?: string|number,
    fontSize?: string|number,
    children?: string|React.ReactNode,
    radius?: string|number,
    color?: string,
    onClick?: () => void,
}

const Tag = (prop: Prop): React.JSX.Element => {
    const propType = prop.type ?? 'fill'
    const propSize = prop.size
    const propPadding = prop.padding
    const propFontSize = prop.fontSize
    const propChildren = prop.children
    const propColor = prop.color ?? Theme.themeColor
    const propRadius = prop.radius
    const propOnClick = prop.onClick

    let displayPadding: number|string = '0px'
    let displayFontSize: number|string = '14px'
    let displayColor: string = '#ffffff'
    let displayBackgroundColor: string = Theme.themeColor
    let displayRadius: number|string = '2px'

    switch (propSize) {
        case 'small': {
            displayPadding = '2px 4px'
            displayFontSize = '12px'
            displayRadius = '2px'
            break
        }
        case 'normal': {
            displayPadding = '4px 8px'
            displayFontSize = '14px'
            displayRadius = '4px'
            break
        }
        case 'large': {
            displayPadding = '6px 12px'
            displayFontSize = '16px'
            displayRadius = '6px'
            break
        }
    }

    if (propPadding) displayPadding = propPadding
    if (propFontSize) displayFontSize = propFontSize
    if (propRadius) displayRadius = propRadius


    switch (propType) {
        case "fill": {
            displayColor = '#ffffff'
            displayBackgroundColor = propColor
            break
        }
        case 'outline': {
            displayColor = propColor
            displayBackgroundColor = '#ffffff'
            break
        }
    }

    return (
        <div
            className={styles.container}
            onClick={propOnClick}
            style={{
                padding: displayPadding,
                fontSize: displayFontSize,
                borderRadius: displayRadius,
                color: displayColor,
                backgroundColor: displayBackgroundColor,
                border: `1px solid ${propColor}`,
                width: "fit-content"
            }}
        >
            { propChildren }
        </div>
    )
}

export default Tag
