class SimpleError extends Error {
    constructor(message: string) {
        super(message);
    }

    toString() {
        return this.message;
    }
}


class AuthError extends Error {
    constructor(message: string = '登录信息失效，请重新登录') {
        super(message);
    }

    toString() {
        return this.message;
    }
}

export {
    SimpleError,
    AuthError,
}
