import React from "react";


import styles from './index.module.css';
import {NetworkBillRecord, NetworkBillRecordType} from "../../../../module/network/network_billrecord";
import Flex from "../../../../compoment/Flex";
import BillTagCache from "../../../../module/cache/billtag_cache";
import Tag from "../../../../compoment/Tag";
import Divider from "../../../../compoment/Divider";


interface Prop {
    billRecord: NetworkBillRecord,
    onTap: () => void,
}


const BillRecord = (prop: Prop) : React.JSX.Element => {
    const { billRecord, onTap } = prop

    const getTimeStr = (time: number): string => {
        const date = new Date(time * 1000)
        let yearStr = date.getFullYear().toString()
        let monthStr = (date.getMonth() + 1).toString()
        let dayStr = date.getDate().toString()
        let hourStr = date.getHours().toString()
        let minuteStr = date.getMinutes().toString()
        let secondStr = date.getSeconds().toString()
        if (monthStr.length === 1) {
            monthStr = `0${monthStr}`
        }
        if (dayStr.length === 1) {
            dayStr = `0${dayStr}`
        }
        if (hourStr.length === 1) {
            hourStr = `0${hourStr}`
        }
        if (minuteStr.length === 1) {
            minuteStr = `0${minuteStr}`
        }
        if (secondStr.length === 1) {
            secondStr = `0${secondStr}`
        }
        return `${yearStr}年${monthStr}月${dayStr}日 ${hourStr}:${minuteStr}:${secondStr}`
    }


    const getNameStr = (billRecord: NetworkBillRecord) : string => {
        if (billRecord.name) {
            return billRecord.name
        }
        if (billRecord.type === 0) {
            return "支出"
        }
        if (billRecord.type === 1) {
            return "收入"
        }
        return "记账"
    }


    const getMoneyClassName = (billRecord: NetworkBillRecord) : string => {
        let resultClassName = styles.money
        if (billRecord.type === NetworkBillRecordType.OutCome) {
            resultClassName += ` ${styles.moneyOutcome}`
        }
        if (billRecord.type === NetworkBillRecordType.InCome) {
            resultClassName += ` ${styles.moneyIncome}`
        }
        return resultClassName
    }

    const billRecordHasTag = billRecord.tags.length > 0

    return (
        <Flex className={styles.container} orientation='column' justify='flex-start' align='flex-start' onClick={onTap}>
            <Flex orientation='row' width='100%' align='center'>
                <Flex orientation='column' flex={1}>
                    <span className={styles.name}>{getNameStr(billRecord)}</span>
                    <span className={styles.time}>{getTimeStr(billRecord.time)}</span>
                </Flex>
                <span className={getMoneyClassName(billRecord)}>{billRecord.money}</span>
            </Flex>
            { billRecordHasTag ? <Divider startDistance='8px' /> : null }
            <Flex marginTop={billRecordHasTag ? 8 : 0} wrap="wrap" orientation='row' gap='4px'>
                {
                    billRecord.tags.map((tagID) => {
                        const loopTag = BillTagCache.getTagInCache(tagID)
                        const loopTagColor = loopTag === undefined ? '#999' : BillTagCache.getTagColor(tagID)
                        const loopTagText = loopTag?.name ?? `未知标签<${tagID}>`

                        return (
                            <Tag
                                type='fill'
                                size='small'
                                color={loopTagColor}
                            >
                                {loopTagText}
                            </Tag>
                        )
                    })
                }
            </Flex>
        </Flex>
    );
}


export default BillRecord;
